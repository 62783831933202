<div class="table-header" *ngIf="tableHeaders">
  <div class="header-left">
    <ng-content select="[t-left]"></ng-content>
  </div>

  <div class="header-right" *ngIf="!headers">
    <ng-content select=".ui.mini.icon.buttons"></ng-content>
  </div>

  <div class="header-right" *ngIf="headers">
    <ng-content select="[t-right]"></ng-content>

    <mat-form-field [formGroup]="filterFormGroup">
      <mat-icon matPrefix>search</mat-icon>

      <input matInput textSanitizer  placeholder="Buscar" formControlName="search">

      <button
        [disabled]="!filterFormGroup.dirty"
        mat-icon-button
        matSuffix
        (click)="filterFormGroup.reset(); $event.stopPropagation();"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <button *ngIf="filters?.length" mat-icon-button (click)="openFilters()">
      <mat-icon aria-label="Filtros">filter_list</mat-icon>
    </button>

    <button mat-icon-button (click)="onDownload.emit()">
      <mat-icon class="kobra-icon" aria-label="Descargar">file_download</mat-icon>
    </button>
  </div>

</div>

<div class="{{classContainer}} paginator">
  <div class="actions shadow-chico" align="right">
    <ng-content select="[t-paginator]"></ng-content>
  </div>
</div>

<div style="background-color: white; margin-top: -20px">
  <div class="{{classContainer}}">
    <app-loader *ngIf="loading" [message]="'Cargando información...'"></app-loader>
    <div style="margin-bottom: 10px;">
      <ng-content select="[t-header]"></ng-content>
    </div>

    <table
      *ngIf="dataSource"
      mat-table
      [dataSource]="dataSource"
      matSort
      class="shadow-chico"
      (matSortChange)="sortData($event)"
      [ngClass]="{'hide': loading}"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="encabezado">
          <mat-checkbox (change)="$event ? masterToggle() : null"
            class="kobra-checkbox"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()" *ngIf="multiSelect">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            class="kobra-checkbox"
            (change)="seleccionar(row)"
            [checked]="disableRowSelection && disableRowSelection(row) ? false : selection.isSelected(row)"
            [disabled]="disableRowSelection ? disableRowSelection(row) : false"
            [aria-label]="checkboxLabel(row)" *ngIf="multiSelect">
          </mat-checkbox>
        </td>
      </ng-container>


      <ng-container *ngFor="let column of columns" [matColumnDef]="column.name">
        <th class="encabezado" mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="column.hide" style="font-weight: 550;" class="generic-mat-cell" sticky> {{ column.name }} </th>

        <td mat-cell *matCellDef="let item" [hidden]="column.hide" class="{{column.class ? column.class : 'generic-mat-cell'}}">
          <ng-container *ngIf="!column.type || column.type === 'string'" >
            <div style="display: flex; align-items: center;">
              <button *ngIf="column.rowIcon && item.activarIcono"
                class="{{column.class ? column.class : column.tooltip}}"
                mat-icon-button
                (click)="linkRow($event, item, column.key)"
                [matTooltip]="column.tooltip"
                mat-tooltip-panel-above>
                <mat-icon>{{column.icono}}</mat-icon>
              </button>
              {{ item[column.key] }}
            </div>

          </ng-container>

          <ng-container *ngIf="column.type === 'boolean'">
            {{ item[column.key] === true || item[column.key] == '1' ? 'Sí' : (!item[column.key] === false || item[column.key] == '0' ? 'No' : '') }}
          </ng-container>

          <ng-container *ngIf="column.type === 'dateTime'">
            {{ item[column.key] ? momentDate(item[column.key]).format('DD/MM/YYYY HH:mm') : '' }}
          </ng-container>

          <ng-container *ngIf="column.type === 'dateTimeLocal'">
            {{ item[column.key] ? utcConversor.convertDateToLocalView(item[column.key], false) : '' }}
          </ng-container>

          <ng-container *ngIf="column.type === 'date'">
            {{ item[column.key] ? momentDate(item[column.key]).format('DD/MM/YYYY') : '' }}
          </ng-container>

          <ng-container *ngIf="column.type === 'money'">
            <ng-container *ngIf="!column.link">
              <span [ngClass]="{'money-green': item[column.key] > 0, 'money-red': item[column.key] < 0}">{{ item[column.key] | currency }}</span>
            </ng-container>
            <a *ngIf="column.link" (click)="linkRow($event, item, column.key)">{{ item[column.key] | currency }}</a>
          </ng-container>

          <ng-container *ngIf="column.type === 'number'">
            <span *ngIf="!column.link">{{ utils.formatearNumero(item[column.key]) }}</span>
            <a *ngIf="column.link" (click)="linkRow($event, item, column.key)">{{ utils.formatearNumero(item[column.key]) }}</a>
          </ng-container>

          <ng-container *ngIf="column.type === 'link'">
            <a class="sombreado-link" *ngIf="column.link" routerLink="{{column.link + item[column.key]}}">{{ item[column.key] }}</a>
            <a class="sombreado-link" *ngIf="!column.link" (click)="handleLinkClick($event, item, column.key)">{{ item[column.key] }}</a>
          </ng-container>

          <ng-container *ngIf="column.type === 'icono'">
            <button
              type="button"
              class="{{column.class ? column.class : column.tooltip}}"
              mat-icon-button
              (click)="linkRow($event, item, column.key)"
              [matTooltip]="column.tooltip"
              mat-tooltip-panel-above>
              <mat-icon>{{column.icono}}</mat-icon>
            </button>
          </ng-container>

          <ng-container *ngIf="column.type === 'copy'">
            <button mat-icon-button
              matTooltip="Copiar variable"
              mat-tooltip-panel-above
              ngxClipboard [cbContent]="item[column.key]"
              aria-label="Button that displays a tooltip when focused or hovered over"
            ><mat-icon class="kobra-icon">file_copy</mat-icon>
          </button>
          </ng-container>

          <ng-container *ngIf="column.type === 'estatus'">
            <span [ngClass]="getStatusClass(item[column.key])">
              {{ getverificaTextoEstatus(item[column.key]) }}
            </span>
          </ng-container>

          <ng-container *ngIf="column.type === 'icon'">
            <img [src]="item[column.key]" style="width: 35px;"/>
          </ng-container>

          <ng-container *ngIf="column.type === 'button'">
            <button mat-stroked-button class="botonGuardar" (click)="linkRow($event, item, column.key)">{{column.key}}</button>
          </ng-container>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="{highlight: selectedRow === row  }"
      (click)="seleccionar(row);"
      (dblclick)="onDblClick(row)"></tr>
    </table>

      <div *ngIf="items && items.length == 0 && !loading" style="text-align: center; margin-top: 20px; margin-bottom: 20px;">Sin información disponible</div>
  </div>

  <div>
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
