import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { ServiciosKobraService } from '../core/http/servicios-kobra/servicios-kobra.service';
import { ServicesVisitasService } from '../core/http/services-visitas/services-visitas.service';
import { Checklist } from "../catalogos/checklists/modelos/checklist.model";
import { PaymentMethod } from '@lender/payment-method.model';

@Injectable()
export class ChecklistService {

  constructor(
    private leanServ: ServiciosKobraService,
    private serviciosVisitas: ServicesVisitasService,
  ) { }

  public getChecklist(visitID: number, idAgent: number, idCliente: number, idFinanciera: number): Observable<any> {
    return this.serviciosVisitas.get(`/visitas/${visitID}/checklist?idAgent=`+idAgent+'&idCliente='+idCliente+'&idFinanciera='+idFinanciera)
      .pipe(
        map((res) => {
          return res.data as any;
        })
      );
  }

  public getChecklistRequest(solicitudID: number, idAgent: number, lenderID: number): Observable<any> {
    return this.serviciosVisitas.get(`/clientes/${lenderID}/creditos/solicitudes/${solicitudID}/checklist?idAgent=`+idAgent)
      .pipe(
        map((res) => {
          return res.data as any;
        })
      );
  }

  public getTypesAnswers() {
    return this.serviciosVisitas.get('/checklist/tipos-respuestas').pipe(map(res => res.data.map((item) => ({
      id: item.idTipoRespuesta,
      name: item.nombre
    }))));
  }

  public getActionsAnswers() {
    return this.serviciosVisitas.get('/checklist/acciones-respuestas').pipe(map(res => res.data.map((item) => ({
      id: item.idAccionRespuesta,
      name: item.nombre,
      descripcion: item.descripcion
    }))));
  }

  public obtenerChecklistProductoPorCliente(idCliente: number): Observable<any> {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/checklists-producto`)
      .pipe(
        map((res) => {
          let response: any = res;
          var checklists = Checklist.mapArray(response.data);
          return checklists;
        })
      );
  }

  public obtenerMetodosPago(): Observable<any> {
    return this.serviciosVisitas.get(`/visitas-metodos-pago`)
      .pipe(
        map((res) => {
          let response: any = res;
          let metodosPago: PaymentMethod[] = [];
          if(res.data && res.data.length > 0){
            res.data.map(metodoPago=>{
              metodosPago.push({
                id: metodoPago.idMetodoPago,
                name: metodoPago.nombre
              });
            });
          }
          return metodosPago;
        })
      );
  }

  public obtenerProducto(idCliente: number, idProducto: number){
    return this.serviciosVisitas.get(`/clientes/${idCliente}/productos/${idProducto}`);
  }
  
}
