import { Component, Optional, ViewChild } from '@angular/core';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { PreCobradorMovimiento, PreCobradorMovimientoPagination } from './ajuste-movimiento-agentes.interface';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';
import { LenderService } from '@lender/lender.service';
import { AgentService } from '@agent/agent.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { PreAgentesMovimientosService } from './ajuste-movimiento-agentes.service';
import { ConfirmacionDialog } from 'src/app/modales-genericos/confirmacion/confirmacion-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, take } from 'rxjs';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { CobradoresFacturacionesPeriodosService } from '../cobradores-facturaciones-periodos/cobradores-facturaciones-periodos.service';
import { ConceptosSaldosService } from '../conceptos-saldos/conceptos-saldos.service';
import { MatSidenav } from '@angular/material/sidenav';
import { UtcConversor } from 'src/app/shared/components/utc-conversor';
import { DecodedToken } from 'src/app/authentication/decoded-token.interface';
import jwtDecode from 'jwt-decode';


@Component({
  selector: 'app-ajuste-movimiento-agentes',
  templateUrl: './ajuste-movimiento-agentes.component.html',
  styleUrls: ['./ajuste-movimiento-agentes.component.css'],
  providers: [
    LenderService,
    AgentService
  ]
})
export class AjusteMovimientoAgentesComponent {
  public tablaMovimiento: any;
  public movs: any[];
  itemsSelected: PreCobradorMovimiento[] = [];
  sessionData: SessionData;
  public hoy: Date = new Date();

  public fechaInicioDefault: Date = new Date(this.hoy.getTime() - 30 * 24 * 60 * 60 * 1000);
  public cargandoLista: boolean = false;
  public hijosActivos: boolean = false;
  public filter: boolean = false;
  public filtrosAbiertos: boolean = false;
  public ayuda: boolean = false;
  public pagination: PreCobradorMovimientoPagination;
  public queryParamsGlobal: string;
  public seValidoFechaInicio: boolean = false;
  public seValidoFechaFin: boolean = false;
  public totalFiltros: number = 0;
  public pageSize = 50;
  public contabilizadorFiltros: FiltersContabilizer;
  @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;

  public filtros = {
    cobradorFacturacionPeriodo: null,
    nombrePeriodo: null,
    cobrador: null,
    nombreCobrador: null,
    concepto: null,
    nombreConcepto: null,
    estatus: null,
    fechaInicio: this.fechaInicioDefault,
    fechaFin: this.hoy,
    page: 1,
    sortField: 'pcm.created_at',
    sortDirection: 'desc'
  }
  permiso: boolean = false;

  constructor(
    public filtrosService: FiltrosService,
    private app: ApplicationService,
    public preAgentesMovServ: PreAgentesMovimientosService,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private agenteServ: AgentService,
    private periodoServ: CobradoresFacturacionesPeriodosService,
    private conceptosServ: ConceptosSaldosService,
    

    @Optional() public dialogRef: MatDialogRef<AjusteMovimientoAgentesComponent>

  ) {
    this.sessionData = this.localStorageService.getSessionData();
    this.crearTabla();
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    this.contabilizadorDeFiltrosTotales();

    const token: string | null = localStorage.getItem('access-token');
    if (!token) {
        console.log('No se encontró el token.');
        return;
    }

    // Decodificar el token JWT para obtener el tiempo de expiración
    const decodedToken: DecodedToken = jwtDecode<DecodedToken>(token);
    const roles: number[] = decodedToken.data.roles;

    console.log(roles);
    if (roles) {
      if (roles.includes(5)) {
        this.permiso = true;
      }
    }

  }

  ngOnInit() {
    this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.resultFiltros.bind(this));
    
  }

  private agregarContabilizadoresDeFiltros() {

    this.contabilizadorFiltros.add('Generales', 'generales', [
      'estatus',
      'concepto',
      'cobradorFacturacionPeriodo'
    ]);
    this.contabilizadorFiltros.add('Fechas registro', 'fechas', [
      'fechaInicio',
      'fechaFin'
    ]);
    this.contabilizadorFiltros.add('Fechas movimiento', 'agente', [
      'cobrador'
    ]);
  }


  public contabilizadorDeFiltros(filtroName: string) {
    return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros);
  }

  public contabilizadorDeFiltrosTotales(): void {
    let totalFiltrosArray: number[] = [];

    totalFiltrosArray.push(this.contabilizadorDeFiltros('generales'));
    totalFiltrosArray.push(this.contabilizadorDeFiltros('fechas'));
    totalFiltrosArray.push(this.contabilizadorDeFiltros('agente'));

    this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  crearTabla() {
    this.tablaMovimiento = {
      headers: false,
      multiSelect: true,
      columns: [
        {
          name: 'Periodo',
          key: 'nombrePeriodo',
          hide: false
        },
        {
          name: 'ID Agente',
          key: 'idCobrador',
          hide: false
        },
        {
          name: 'Agente',
          key: 'nombreCobrador',
          hide: false
        },
        {
          name: 'Concepto',
          key: 'nombreConcepto',
          hide: false
        },
        {
          name: 'Estatus',
          key: 'estatus',
          type: 'estatus',
          hide: false
        },
        {
          name: 'Monto',
          key: 'monto',
          hide: false,
          type: 'money'
        },
        {
          name: 'Fecha Movimiento',
          key: 'createdAt',
          hide: false,
          type: 'dateTimeLocal'
        }
      ]
    }
  }

  public obtieneMovExcel(): void {
    const gridSize = this.pagination.totalRows

    if (gridSize > 30000) {
      this.app.showSnackbar("Aviso", "El limite para la descarga es de 30,000 registros", 3000, 'warning')
      return;
    }

    if (gridSize >= 1) {
      let queryParams = "";
      queryParams += '?rows=' + this.pageSize;
       queryParams += '&idClienteAdministrador=' + this.sessionData.idClienteAdministrador + this.queryParamsGlobal;

      this.preAgentesMovServ.descargarMovimientosAgentesExcel(queryParams)
        .pipe(take(1))
        .subscribe((respuesta) => {
          this.app.showSnackbar("Aviso", "Tu archivo estará listo en unos momentos, para poder descargarlo ir a la opción de descargas.", 3000, 'success');
        }, (error: any) => {
          this.app.showError(error);
        });
    } else {
      this.app.showSnackbar("Aviso", "No hay resultados para descargar", 3000, 'warning');
    }

  }

  public obtieneMovimientos(page: number) {
    this.cargandoLista = true;
    const campos = '"idPreCobradorMov","idCobradorFacturacionPeriodo","nombrePeriodo","idCobrador","nombreCobrador",' +
      '"idEstatus","estatus","idConcepto","nombreConcepto","nombreMovimiento","monto","createdAt","cargo"';
    let queryParams = `?_paginate=1&_page=${page}&_rows=${this.pageSize}&_slt=${campos}&_orderBy=${this.filtros.sortField}${this.filtros.sortDirection ? ' ' + this.filtros.sortDirection : ''}`;
    queryParams += this.queryParamFiltros(page);
    this.preAgentesMovServ.obtenerMovimientos(queryParams).subscribe(
      (res: any) => {
        if (res.success) {
          const datos = res.data['pageData'];
          this.pagination = {
            page: res.data['page'],
            pageData: datos,
            pages: res.data['pages'],
            totalRows: res.data['totalRows'],
          };

          for (let i = 0; i < datos.length; i++) {
       
            datos[i].isCargo = !!datos[i].cargo;
            datos[i].cargo = datos[i].cargo ? 'Cargo' : 'Abono';
          }
          this.movs = datos;
        }
        this.cargandoLista = false;

      }, (error) => {
        this.app.showError(error);
        this.cargandoLista = false;
      },
    );
  }

  queryParamFiltros(page: number) {
    this.queryParamsGlobal = "";
    if (this.filtros.cobradorFacturacionPeriodo == null) {

      this.queryParamsGlobal += this.filtros.fechaInicio ?
        `&fechaInicio=${this.filtros.fechaInicio.toISOString().substr(0, 10)}` : '';

      this.queryParamsGlobal += this.filtros.fechaFin ?
        `&fechaFin=${this.filtros.fechaFin.toISOString().substr(0, 10)}` : '';
    }

    this.queryParamsGlobal += this.filtros.cobradorFacturacionPeriodo ?
    `&idCobradorFacturacionPeriodo=${this.filtros.cobradorFacturacionPeriodo.idCobradorFacturacionPeriodo}` : '';


    this.queryParamsGlobal += this.filtros.cobrador ? `&idCobrador=${this.filtros.cobrador.idCobrador}` : '';
    this.queryParamsGlobal += this.filtros.concepto ? `&idConcepto=${this.filtros.concepto.idConcepto}` : '';

    if (this.filtros.estatus ) {
      this.queryParamsGlobal += `&estatus=${this.filtros.estatus}`;
    }

    if (page != this.filtros.page) {
      this.filtros.page = page;
      this.filtrosService.guardarFiltros(this.filtros);
    }


    return this.queryParamsGlobal;
  }

  setSortField(sort: any) {
    this.filtros.sortDirection = sort.direction;
    if (sort.active != this.filtros.sortField) {
      this.filtros.sortField = sort.active;
    }
    this.obtieneMovimientos(1);
  }

  resultFiltros(filtrosNvos: any) {
    if (filtrosNvos) {
      this.filter = true;
      this.filtros = filtrosNvos;
    }
    this.obtieneMovimientos(this.filtros.page || 1);

    
  }

  onAutorizarMovimientos() {
    if (!this.permiso) {
      this.app.showSnackbar('Aviso', 'No tienes permisos para autorizar movimientos', 3000, 'warning');
      return;
    }
    const dialogRef = this.dialog.open(ConfirmacionDialog, {
      data: {
        titulo: "Confirmar",
        mensaje: `¿Deseas autorizar los movimientos seleccionados?`,
        icono: "save",
        boton1: "No",
        boton2: "Sí",
        claseAccion: "boton-accion-guardar"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const loading = this.app.showLoading('Autorizando movimientos...');
        this.preAgentesMovServ.autorizarMovimiento(this.itemsSelected)
          .pipe(take(1))
          .subscribe({
            next: (res: any) => {
              if (res.success) {
                this.app.showSnackbar('¡Aviso!', 'Los movimientos han sido autorizados correctamente.', 3000, 'success');
                this.app.hideLoading(loading);
                this.dialogRef.close(true);
              } else {
                this.app.showError(res.error.message);
              }
            },
            error: (error: any) => {
              this.app.showSnackbar('¡Aviso!', 'Ha ocurrido un error al autorizar los movimientos.', 3000, 'error');
              this.app.hideLoading(loading);
            },
            complete: () => {
              this.app.hideLoading(loading);
              this.obtieneMovimientos(1);
            }
          });
      }
    });
  }


  onCancelarMovimientos() {
    const dialogRef = this.dialog.open(ConfirmacionDialog, {
      data: {
        titulo: "Confirmar",
        mensaje: `¿Deseas cancelar los movimientos seleccionados?`,
        icono: "save",
        boton1: "No",
        boton2: "Sí",
        claseAccion: "boton-accion-guardar"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const loading = this.app.showLoading('Cancelando movimientos...');
        this.preAgentesMovServ.cancelarMovimiento(this.itemsSelected)
          .pipe(take(1))
          .subscribe({
            next: (res: any) => {
              if (res.success) {
                this.app.showSnackbar('¡Aviso!', 'Los movimientos han sido cancelados correctamente.', 3000, 'success');
                this.app.hideLoading(loading);
                this.dialogRef.close(true);
              } else {
                this.app.showError(res.error.message);
              }
            },
            error: (error: any) => {
              this.app.showSnackbar('¡Aviso!', 'Ha ocurrido un error al cancelar los movimientos.', 3000, 'error');
              this.app.hideLoading(loading);
            },
            complete: () => {
              this.app.hideLoading(loading);
              this.obtieneMovimientos(1);
            }
          });
      }
    });
  }

  onPage(newPagina: any): void {
    if (newPagina.pageIndex + 1 !== this.pagination.page || newPagina.pageSize !== this.pageSize) {
      this.pageSize = newPagina.pageSize;
      this.obtieneMovimientos(newPagina.pageIndex + 1);
    }
  }

  inactivarHijo() {
    this.filtrosService.asignarOpcion();
    if (this.filter) {
      this.filtrosService.guardarFiltros(this.filtros, true);
    }
    this.filtrosService.obtenerFiltrosIniciales(this.filtros, this.resultFiltros.bind(this));
  }


  public onSelected(rows: any[]): void {
    // Filtrar las filas válidas (por ejemplo, donde idEstatus es 1, es decir, "Pendiente autorizar")
    const validRows = rows.filter(row => row.idEstatus === 1);

    // Si no hay filas válidas, cancelamos la acción y, opcionalmente, mostramos un mensaje
    if (validRows.length === 0) {
      return; // Salir sin procesar ni emitir nada
    }

    // Si hay filas válidas, asignarlas y continuar con la lógica
    this.itemsSelected = validRows;
    console.log(this.itemsSelected);

  }

  disableRowSelectionFn = (row: any): boolean => row.idEstatus !== 1;


  filterModal(parametro: boolean) {
    if (parametro) {
      Object.keys(this.filtros).forEach(key => {
        if (key != 'page' && this.filtros[key]) {
          this.filter = true;
        }
      });


      if (
        (this.filtros.fechaInicio &&
          !this.filtros.fechaFin)
        ||
        (!this.filtros.fechaInicio &&
          this.filtros.fechaFin)

      ) {
        this.app.showSnackbar('Aviso', 'Es necesario elegir rangos de fecha válidos', 3000, 'warning');
        return;
      }

 

      if (this.filter) {
        this.filtros.page = 1;
        //this.obtieneMovimientos(1);
        this.resultFiltros(null);
      }
    } else {

      this.removerFiltros();
      this.filter = false;
      this.filtros.page = 1;
      this.filtros.sortField = 'pcm.created_at desc';
      this.filtros.cobradorFacturacionPeriodo;
      this.obtieneMovimientos(1);
    }
    this.filtrosAbiertos = !this.filtrosAbiertos;
    this.filtrosSideNav.toggle();
    this.contabilizadorDeFiltrosTotales();
  }

  removerFiltros() {
    Object.keys(this.filtros).forEach(key => {
      this.filtros[key] = null;
    });
  }


  public agenteFilterSearch = function (term: string) {
    return this.agenteServ.obtenerAgentes(term)
      .pipe(
        take(1),
        map((res: any) => {
          if (res.data) {
            return res.data.map(({ idCobrador, nombreCompleto, idAgente }) => ({
              idAgente: idAgente,
              nombre: `${idCobrador} - ${nombreCompleto}`,
              idCobrador: idCobrador
            }));
          } else {
            return []; // Devuelve un arreglo vacío si no hay datos
          }
        })
      );
  }.bind(this);

  public periodosFilterSearch = function (term: string) {
    this.limpiarFechas();
    return this.periodoServ.searchPeriodos(term)
      .pipe(
        take(1),
        map((res: any) => res.data.map(

          ({ idCobradorFacturacionPeriodo, nombre }) => (
            { idCobradorFacturacionPeriodo: idCobradorFacturacionPeriodo, nombre: `${idCobradorFacturacionPeriodo} - ${nombre}` }
          )

        ))
      );
  }.bind(this);

  public buscarConceptos = function (term: string) {
    return this.conceptosServ.buscarConceptos(term)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idConcepto, nombre, idTipoConcepto }) => (
            { idConcepto: idConcepto, nombre: nombre, idTipoConcepto: idTipoConcepto }
          )
        ))
      );
  }.bind(this);

  limpiarFechas() {
    this.filtros.fechaInicio = null;
    this.filtros.fechaFin = null;
  }

  setFechaFin(fecha: any) {
    if (!this.filtros.fechaFin) {
      this.filtros.fechaFin = fecha;
    }
  }

  checkControl(control: string) {
    return !this.preAgentesMovServ[control] && control == 'fechaInicio' ? this.seValidoFechaInicio : this.seValidoFechaFin;
  }

}
