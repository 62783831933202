import { Validator } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/internal/operators/take';
import { RequestService } from './request.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { Request } from './request.model';
import { ChecklistService } from "../checklist/checklist.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { environmentSelector } from 'src/environments/environment.selector';
import { ChecklistDinamico } from "../visit/checklist-dinamico-modelos/checklist-dinamico.model";
import { Pregunta } from "../visit/checklist-dinamico-modelos/checklist-dinamico-pregunta.model";
import { SessionData } from './../shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { Coordenadas } from '../mapbox/modelos/coordenadas.model';
import { FotoModalComponent } from 'src/app/componentes/fotos/modal/foto-modal.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { TableDialogComponent } from '../shared/components/table/dialog/table-dialog.component';
import { Utils } from '../shared/components/utils';
@Component({
  selector: 'app-credit-request',
  templateUrl: './credit-request.component.html',
  styleUrls: ['./credit-request.component.css']
  ,
  providers: [
    RequestService,
    ChecklistService,
  ]
})
export class CreditRequestComponent implements OnInit {
  private environment = environmentSelector();
  public momentFecha;
  public loader: boolean;
  public textLoader: string;
  public etiquetaActual: any = null;
  public request;
  public idFinanciera: number;
  public imgSignature;
  public token = localStorage.getItem('access-token');
  public urlStorage = this.environment.kobraStorage;
  public loadingChecklist: boolean;
  public checklists: ChecklistDinamico;
  public cambiarTextos = false;
  sessionData: SessionData;
  public uriFoto: string;
  public coordenadas: string;
  public coordenadasMapa: Coordenadas[];
  public labelMunicipio: string = 'Municipio';
  public labelEstado: string = 'Estado';
  isComentario: boolean = false;
    isComentarioIA: boolean = true;
  dialog: MatDialog;
  coordenadasTemp: any;

  constructor(
    private requestService: RequestService,
    private app: ApplicationService,
    private route: ActivatedRoute,
    private checklistService: ChecklistService,
    private localStorageService: LocalStorageService
  ) {
    this.coordenadasMapa = [];
    this.labelMunicipio = this.environment.labelMunicipio;
    this.labelEstado = this.environment.labelEstado;
    this.sessionData = this.localStorageService.getSessionData();
  }

  ngOnInit() {
    this.momentFecha = moment;
    this.readParams();
    this.checklists = new ChecklistDinamico();

  }

  private readParams(): void {
    this.route.params.subscribe(
      (params: Params) => {

        if (params['id']) {
          this.loadRequest(params['id'], params['idFinanciera']);
        }
      }
    );
  }


  private loadRequest(id: number, idFinanciera: number): void {
    const queryParams: string = '';
    this.textLoader = 'Cargando detalle...';
    this.loader = true;
    this.coordenadasMapa = [];
     this.coordenadasTemp = [];

    this.requestService.getOne(id, idFinanciera, queryParams)
      .pipe(take(1))
      .subscribe((request: Request) => {

        this.request = request;
        this.loadChecklist(this.request.idSolicitudCredito, this.request.idAgente, this.request.idFinanciera);
        this.uriFoto = `${this.urlStorage}/clientes/${request.idFinanciera}/creditos/solicitudes/${request.idSolicitudCredito}/firma?token=${this.token}`;
        this.idFinanciera = request.idFinanciera;
        this.coordenadasTemp.push({
          lat: request.latitudFin,
          lng: request.longitudFin
        });
        this.coordenadas = request.latitudFin + ',' + request.longitudFin;

        setTimeout(() => {

        }, 200);

        this.coordenadasMapa = this.coordenadasTemp;


      }, (error: any) => {
        this.app.showError(error);
        this.loader = false;

      }
      );
  }


  private loadChecklist(id: number, idAgent: number, lenderID: number): void {
    this.loadingChecklist = true;
    this.checklists.preguntas = [];
    this.checklistService.getChecklistRequest(id, idAgent, lenderID)
      .subscribe(data => {
        this.checklists.preguntas = Pregunta.mapArray(data);
        let country = "&Country=";
        country+= this.environment.country;
        for (let p = 0; p < this.checklists.preguntas.length; p++) {
          for(let r = 0; r < this.checklists.preguntas[p].respuestas.length; r++){
              let respuesta = this.checklists.preguntas[p].respuestas[r];
              if(respuesta.idTipoRespuesta == 11 && respuesta.valorRespuesta){
                  respuesta.fullPath =
                      `${this.environment
                        .kobraServices.storage}/checklists/foto?nombre=${respuesta.valorRespuesta}&token=${localStorage.getItem('access-token')}${country}`;
              }
              if(respuesta.idTipoRespuesta == 12){
                for (let c = 0; c < respuesta.columnas.length; c++) {
                  const columna = respuesta.columnas[c];
                  if(!respuesta.columnsTabla){
                    respuesta.columnsTabla = [];
                  }
                  respuesta.columnsTabla.push({
                    name: columna.nombre,
                    key: columna.idChecklistRespuesta.toString(),
                    type: columna.idTipoRespuesta == 5 ? 'date' :
                      (columna.idTipoRespuesta == 6 || columna.idTipoRespuesta == 7 || columna.idTipoRespuesta == 8 ? 'boolean' :
                        (columna.idTipoRespuesta == 3 || columna.idTipoRespuesta == 4 ? 'number' : 'string')
                      )
                  });
                }
              }
          }
      }

        this.loadingChecklist = false;

      }, (error: string) => {
        this.loadingChecklist = false;
        this.app.showError(error);
      });
  }

  public onClickMapaGoogle(coordenadas: string) {
    window.open(`https://www.google.com/maps/search/?api=1&query=${coordenadas}`, '_blank');
  }

  public cargarFotoCompleta(urlFoto: string) {
    const dialogRef = this.dialog.open(FotoModalComponent, {
      data: {
        urlFoto: urlFoto
      }
    });

    dialogRef.afterClosed().subscribe(data => { });
  }
  getAnswerValue(respuesta: any): string {
    let valor: string = "";
    if (respuesta.idTipoRespuesta === 8 || respuesta.idTipoRespuesta === 6) {
      if (respuesta.valorRespuesta === 'true' || respuesta.valorRespuesta === "1") {
        return 'Sí';
      } else if (respuesta.valorRespuesta === 'false' || respuesta.valorRespuesta === "0") {
        return 'No';
      } else {
        return 'Sin respuesta';
      }
    }

    if (respuesta.idTipoRespuesta === 3 || respuesta.idTipoRespuesta === 4){
      return respuesta.valorRespuesta ? Utils.formatearNumero(respuesta.valorRespuesta) : "Sin respuesta";
    }

    if (respuesta.idTipoRespuesta === 5) {
        return respuesta.valorRespuesta ? this.momentFecha(respuesta.valorRespuesta).format('DD/MM/YYYY') : "Sin respuesta";
    }

    return respuesta.valorRespuesta ? respuesta.valorRespuesta : "Sin respuesta";
  }

  getSingleAnswerValue(checklist: any) {

    const respuesta = checklist.respuestas[0];
    return { text: this.getAnswerValue(respuesta), hasPhoto: respuesta.idTipoRespuesta === 11, hasTabla: respuesta.idTipoRespuesta === 12 };
  }

  toggleCommentView(visit: any) {

    if (visit.comentariosIA !== null) {
      this.isComentarioIA = !this.isComentarioIA;
    }

    this.isComentario = !this.isComentario;
  }

  public etiquetaActualObtener(checklist: any): void {
    if (!this.etiquetaActual) {
        this.etiquetaActual = {
            idChecklistPregunta: checklist.idChecklistPregunta,
            idVisitaChecklistDetalle: checklist.idVisitaChecklistDetalle,
            idChecklistRespuesta: checklist.respuestas[0].idChecklistRespuesta,
            idTipoRespuesta: checklist.respuestas[0].idTipoRespuesta,
            valorRespuesta: checklist.respuestas[0].valorRespuesta
        };
    }
  }

  abrirDialogoTabla(checklist: any, respuesta: any) {
    const dialogRef = this.dialog.open(TableDialogComponent, {
      width: '80%',
      data: {
        columns: respuesta.columnsTabla,
        items: respuesta.valorRespuesta,
        headers: false,
        multiselect: false,
        title: checklist.nombrePregunta,
        subtitle: respuesta.nombreRespuesta || ''
      }
    });

  }

  hasValidAnswer(checklist: any): boolean {
    return checklist.respuestas?.some(respuesta => respuesta.valorRespuesta !== null && respuesta.valorRespuesta !== '') ?? false;
  }
}
