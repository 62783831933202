import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { MatPaginatorIntl } from '@angular/material/paginator';
//import { FiltersComponent } from './filters/filters.component';
import * as moment from 'moment';
import { Column } from './interfaces/tabla.interface';
import { Utils } from '../utils';
import { UtcConversor } from '../../components/utc-conversor';


@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, OnDestroy, OnChanges {
    @Input() columns: Array<Column> = [];
    @Input() items: Array<any> = [];
    @Input() filters: Array<any> = [];
    @Input() loading: boolean = false;
    //Valida si se ocultaran los headers por defecto de la tabla para capturar los propios
    //(Para utilizar los propios headers utilizar la clase ui mini icon buttons)
    @Input() headers: boolean = true;
    @Input() tableHeaders: boolean = true;
    @Input() multiSelect: boolean = true;
    @Input() ignoreSortChange: boolean = false;
    @Input() classContainer: string = 'table-container';
    @Input() total: number;
    @Input() disableRowSelection: (row: any) => boolean;


    @Output() onSelectedRows: EventEmitter<any> = new EventEmitter();
    @Output() onLinkRow: EventEmitter<any> = new EventEmitter();
    @Output() sortChange: EventEmitter<any> = new EventEmitter();
    @Output() filtersChange: EventEmitter<any> = new EventEmitter();
    @Output() searchChange: EventEmitter<any> = new EventEmitter();
    @Output() onDownload: EventEmitter<any> = new EventEmitter();
    @Output() onDoubleClick: EventEmitter<any> = new EventEmitter();
    @Output() onCtrlClicLinkRow: EventEmitter<any> = new EventEmitter();

    displayedColumns: string[];
    dataSource;
    selection = new SelectionModel<any>(true, []);
    selectedRow: any;
    subscription: Subscription;
    subscriptionSearch: Subscription;
    filterFormGroup: FormGroup;
    momentDate;
    copiado:any;
    public utils = Utils;
    public utcConversor = UtcConversor;


    constructor(
        private formBuilder: FormBuilder,
        private dialog: MatDialog,
        private paginatorIntl: MatPaginatorIntl
    ) {
        this.momentDate = moment;
    }

    ngOnInit() {
        this.paginatorIntl.itemsPerPageLabel = 'Registros por página:';
        this.paginatorIntl.nextPageLabel = 'Siguiente';
        this.paginatorIntl.previousPageLabel = 'Anterior';
        this.paginatorIntl.firstPageLabel = 'Primera página';
        this.paginatorIntl.lastPageLabel = 'Última página';

        this.paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
            if (length === 0 || pageSize === 0) {
            return `0 de ${length}`;
            }

            const startIndex = page * pageSize;
            const endIndex =
            startIndex + pageSize > length ? length : startIndex + pageSize;

            return `${startIndex + 1} - ${endIndex} de ${length}`;
        };
        this.filterFormGroup = this.formBuilder.group({
            search: ['']
        });

        this.subscriptionSearch = this.filterFormGroup.valueChanges.pipe(debounceTime(350), distinctUntilChanged())
            .subscribe((value) => {
                this.searchChange.emit(value.search);
            });

        this.displayedColumns = ['select'].concat(this.columns.map(c => c.name));
        this.dataSource = new MatTableDataSource<any>(this.items);

        //Se coloca para cuando se van a inicializar registros seleccionados
        this.seleccionarPorDefecto(this.items);

        this.subscription = this.selection.changed.subscribe(data => {
            this.onSelectedRows.emit(data.source.selected);
        });
    }

    ngOnChanges(changes: any) {
        if (changes.items) {
            this.dataSource = new MatTableDataSource<any>(changes.items.currentValue);
            this.selection.clear();
            this.seleccionarPorDefecto(changes.items.currentValue);
            this.limpiarFilasDeshabilitadas(); // Asegurarse de quitar filas deshabilitadas
        }
    }


    ngOnDestroy() {
        if(this.subscription){
            this.subscription.unsubscribe();
            this.subscriptionSearch.unsubscribe();
        }
    }

    private seleccionarPorDefecto(items){
        if(items){
            items.forEach(item => {
                if(item.seleccionado){
                    item.seleccionado = false;
                    this.seleccionar(item);
                }

                if(item.seleccionada){
                    item.seleccionada = false;
                    this.seleccionar(item);
                }
            });
        }
    }

    private limpiarFilasDeshabilitadas() {
        if (this.disableRowSelection) {
          this.selection.selected.forEach(row => {
            if (this.disableRowSelection(row)) {
              this.selection.deselect(row);
            }
          });
        }
      }


    isAllSelected() {
        // Filtramos solo las filas que son seleccionables (no deshabilitadas)
        const selectableRows = this.dataSource.data.filter(row =>
          !(this.disableRowSelection && this.disableRowSelection(row))
        );
        return selectableRows.length > 0 && selectableRows.every(row => this.selection.isSelected(row));
      }


    seleccionar(row: any): void {
        // Si se definió la función y para esta fila la condición se cumple, no se selecciona.
        if (this.disableRowSelection && this.disableRowSelection(row)) {
            // Si la fila ya estaba seleccionada, la deseleccionamos para que el check no quede activo
            if (this.selection.isSelected(row)) {
                this.selection.deselect(row);
            }
            row.seleccionado = false;
            row.seleccionada = false;
            return;
        }

        // Lógica normal de selección
        if (!this.multiSelect) {
            this.selection.clear();
            this.selectedRow = row;
        }
        this.selection.toggle(row);
    }


    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }

        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    linkRow($event, row?: any, columnPressed?: any) {
        $event.stopPropagation();
        this.onLinkRow.emit({ row, columnPressed });
    }

    onDblClick(row) {
        this.onDoubleClick.emit({ row });
    }

    sortData(sort: Sort) {
        if(this.items && this.items.length> 0){
            const column = this.columns.find((c: any) => c.name === sort.active);
            if (!this.ignoreSortChange) {
                this.sortChange.emit({
                    active: column?.sortName || column?.key,
                    direction: sort.direction
                });
            }
            else {
                if (sort.direction == 'asc') {
                    this.dataSource = new MatTableDataSource<any>(this.dataSource.data.sort((a, b) => {
                        var keyA = a[column.key];
                        var keyB = b[column.key];
                        if (keyA > keyB) {
                            return 1;
                        }
                        if (keyA < keyB) {
                            return -1;
                        }
                        return 0;
                    }));
                }
                else if (sort.direction == 'desc') {
                    this.dataSource = new MatTableDataSource<any>(this.dataSource.data.sort((a, b) => {
                        var keyA = a[column.key];
                        var keyB = b[column.key];
                        if (keyA > keyB) {
                            return -1;
                        }
                        if (keyA < keyB) {
                            return 1;
                        }
                        return 0;
                    }));
                }
            }
        }
    }

    getStatusClass(value: any): string {
        if (typeof value === 'boolean') {
            return value ? 'cuentaActivo' : 'cuentaInactiva';
        }

        if (typeof value === 'string') {
            const classes = {
                'Procesado con errores': 'procesado-errores',
                'En cola...': 'en-cola',
                'Cargando...': 'cargando',
                'Cargado': 'cargado',
                'Procesando': 'procesando',
                'Procesado': 'procesado',
                'Geolocalizando': 'geolocalizando...',
                'Geolocalizado': 'geolocalizado',
                'Calculando cobertura': 'calculando-cobertura',
                'Cobertura completa': 'cobertura-completa',
                'Calculando SLA': 'calculando-sla',
                'Terminado': 'terminado',
                'Importado': 'importado',
                'Activa': 'cuentaActiva',
                'Activo': 'cuentaActivo',
                'Visitada': 'cuentaEnEspera',
                'Apartada': 'cuentaApartada',
                'En Espera de Confirmación': 'cuentaEnEsperaDeConfirmacion',
                'Inactiva': 'cuentaInactiva',
                'Por Confirmar': 'cuentaEnEsperaDeConfirmacion',
                'Aceptada': 'terminado',
                'Rechazada': 'procesado-errores',
                'Desasignada': 'en-cola',
                'Reasignada': 'importado',
                'Reasignada (Cuenta inactiva)': 'estatus-defecto',
                'Aprobado': 'terminado',
                'Borrador': 'estatus-defecto',
                'Rechazado': 'procesado-errores',
                'Pendiente confirmación': 'pendiente',
                'Pendiente': 'pendiente',
                'Aplicado': 'cargando',
                'Timbrado': 'terminado',
                'Cancelada': 'calculando-cobertura',
                'Cancelado': 'calculando-cobertura',
                'Error': 'error',
                'Autorizado': 'cuentaActiva',
                'Pendiente autorizar': 'cuentaEnEspera',
            };

            // Si el valor contiene la palabra "Error", retorna la clase 'error'.
            if (value.includes('Error')) {
                return 'error';
            }

            return classes[value] || 'estatus-defecto'; // 'estatus-defecto' es la clase por defecto si no se encuentra una coincidencia
        }

        // Si el valor no es ni booleano ni string, retorna una clase por defecto.
        return 'estatus-defecto';
    }


    handleLinkClick(event: MouseEvent, item: any, key: string): void {
        if (event.ctrlKey) {
          this.handleCtrlLinkClick(event, item, key);
        } else {
          this.linkRow(event, item, key);
        }
    }

    handleCtrlLinkClick($event, row?: any, columnPressed?: any): void {
        $event.stopPropagation();
        this.onCtrlClicLinkRow.emit({ row, columnPressed });
    }

    openFilters() {
        /*const dialogRef = this.dialog.open(FiltersComponent, {
          minWidth: 400,
          disableClose: true,
          data: this.filters
        });

        dialogRef.afterClosed().subscribe(data => {
          if (data) {
            this.filtersChange.emit(data);
          }
        });*/
      }

    getverificaTextoEstatus(value: any): string {
        if (typeof value === 'boolean') {
            return value ? 'Activa' : 'Inactiva';
        }else{
            return value;
        }
    }

    toggleColumnVisibility(columnKey: string, isVisible: boolean): void {
      const column = this.columns.find(col => col.key === columnKey);
      if (column) {
          column.hide = !isVisible; // Se establece directamente el valor
          this.updateDisplayedColumns(); // Actualiza las columnas visibles
      }
    }

  /**
   * Método para actualizar el array de columnas visibles en la tabla
   */
  private updateDisplayedColumns(): void {
      this.displayedColumns = ['select'].concat(this.columns.filter(col => !col.hide).map(col => col.name));
  }

}
