import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { take } from "rxjs/internal/operators/take";
import { ApplicationService } from "../../../application/shared/application.service";
import { Subscription } from "rxjs";
import { VisitService } from "../../../visit/visit.service";
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SessionData } from '../../../shared/interfaces/session-data';
import { AccountService } from "../../account.service";
import {ModalListadoContratosComponent} from '../listado-contratos/listado-contratos.component';
import { UploadFileComponent } from '../../../shared/components/upload-file-v2/upload-file.component';
import { environmentSelector } from "../../../../environments/environment.selector";
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { ConfirmacionDialog } from 'src/app/modales-genericos/confirmacion/confirmacion-dialog.component'; 

@Component({
  selector: 'contratos.component',
  templateUrl: 'contratos.component.html',
  styleUrls: ["contratos.component.css"],
  providers: [
    VisitService,
    AccountService
  ]
})
export class ModalContratosComponent {
  @ViewChild(UploadFileComponent) uploadFileComponent: UploadFileComponent;
  public sessionData: SessionData;
  public cargandoArchivos: boolean = false;
  public descargandoArchivo: boolean = false;
  public cardAyuda: boolean = false;
  public archivos = [];
  public cargandoContratos: boolean = false;
  public file: File[] = [];
  public tieneContratos:boolean = false;
  public isArchivosContratos: boolean = false;
  public archivosContratos: any = [];
  public isConsultar:boolean = false;
  acceptFiles: string = '.pdf';
  desbloquear = false;
  selected = new FormControl(0);
  listado:number;
  private environment = environmentSelector();

  constructor(
    public dialogRef: MatDialogRef<ModalContratosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private app: ApplicationService,
    private visitService: VisitService,
    private localStorageService: LocalStorageService,
    private accountService: AccountService,
    public dialog: MatDialog,
    private http: HttpClient
  ) {
    this.sessionData = this.localStorageService.getSessionData();
    this.archivos = this.data.archivos;
    this.tieneContratos = this.data.tieneContratos;
    this.isConsultar = this.data.isConsultar;
    this.archivosContratos = this.data.archivosContratos;
    this.file = [];
    this.archivos = [];
  }

  onNoClick(): void {
    this.dialogRef.close({tieneContratos: this.tieneContratos,
      success: false,
      data: this.archivosContratos,
      isConsultar: this.isConsultar
    });
  }

  ngOnInit() {
    if(this.isConsultar){
      this.obtenerContractos();
    }

  }

  handleFiles(files: File[]) {
    this.file = files;
    if(this.file.length){
      this.desbloquear = true;
    }else{
      this.desbloquear = false;
    }
  }

  openDialogListadoContracto(): void {

    const dialogRef = this.dialog.open(ModalListadoContratosComponent, {
        data:{
                archivos: this.archivosContratos
            }
    });

    dialogRef.afterClosed().subscribe(result => {
        if(result) {

        }
    });
  }

  public subirContrato() {

    if(this.file === null){

      this.app.showSnackbar(
        '¡Aviso!',
        'Favor de cargar un archivo.',
        3000,
        'warning'
      );
      return;
    }

    const loading: number = this.app.showLoading('Guardando información...');
    this.cargandoContratos = true;
    this.accountService.subirContrato(this.file, null, this.data.idAcreditado, this.data.idCuenta, this.data.idCliente, this.data.idFinanciera, 1)
        .pipe(take(1))
        .subscribe((respuesta) => {
            if (respuesta.success) {
                this.uploadFileComponent.removeFile();
                this.tieneContratos = true;
                this.app.showSnackbar('¡Aviso!', 'El contrato se ha subido correctamente.', 3000, 'success');
                this.app.hideLoading(loading);
                this.cargandoContratos = false;
                this.obtenerContractos();
                /*this.dialogRef.close({
                  tieneContratos: this.tieneContratos,
                  success: true,
                  data: this.archivosContratos,
                  isConsultar: true
                });*/

            }else{
              this.app.hideLoading(loading);
              this.app.showSnackbar('¡Aviso!', 'Error al subir el contrato.', 3000, 'error');
            }
        }, error => {
            this.app.hideLoading(loading);
            this.cargandoContratos = false;
            this.app.showError(error);
        });
  }

  private obtenerContractos(): void {
    this.isArchivosContratos = true;
    let subscription: Subscription = this.visitService.obtenerContratos(this.data.idCliente ,`?idAcreditado=${this.data.idAcreditado}&idCuenta=${this.data.idCuenta}`).subscribe(
        respuesta => {
            if(respuesta.success){
                this.archivosContratos = respuesta.data;
                this.isConsultar = false;
            }else{
                this.app.showSnackbar(
                    "Aviso",
                    "Ocurrio un error al obtener los contratos.",
                    3000,
                    "error"
                );
            }
            this.isArchivosContratos = false;
        }, err => {
            this.isArchivosContratos = false;
            this.app.showError(err);
            subscription.unsubscribe();
        }, () => {
            this.isArchivosContratos = false;
            subscription.unsubscribe();
        }
    );
  }



  public eliminarArchivoContrato(idArchivo: number) {
    const dialogRef = this.dialog.open(ConfirmacionDialog, {
      data: {
        titulo: "Confirmar",
        mensaje: "¿Deseas eliminar el archivo?",
        icono: "delete_forever",
        boton1: "No",
        boton2: "Sí",
        claseAccion: "boton-accion-eliminar"
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.visitService.eliminarContrato(this.data.idCliente, this.data.idAcreditado, this.data.idCuenta, idArchivo)
          .subscribe({
            next: (res) => {
              if (res.success) {
                // Actualiza la lista filtrando el archivo eliminado.
                this.archivosContratos = this.archivosContratos.filter(
                  archivo => archivo.idAcreditadoArchivo !== idArchivo
                );
                this.app.showSnackbar(
                  "¡Aviso!",
                  "El archivo fue eliminado correctamente.",
                  3000,
                  "success"
                );
              } else {
                this.app.showSnackbar(
                  "¡Aviso!",
                  "Ocurrió un error al eliminar el archivo.",
                  3000,
                  "error"
                );
              }
            },
            error: (err: any) => {
              this.app.showError(err);
            }
          });
      }
    });
  }

  public descargarArchivo(archivo: any) {
    this.descargandoArchivo = true;

    let headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('access-token')}`);
    let paisSeleccionado = localStorage.getItem('paisSeleccionado');
    headers = headers.set('Country', paisSeleccionado ? JSON.parse(paisSeleccionado).abreviacion : 'mx');
    const url = `${this.environment.kobraServices.storage}/clientes/${archivo.idCliente}/descargar-archivos?token=${localStorage.getItem('access-token')}&ruta=${archivo.ruta}&nombreArchivo=${archivo.nombre}&nombreArchivoOriginal=${archivo.nombreOriginal}`;

    this.http.get(url, {
      headers,
      responseType: 'blob', // Esperamos una respuesta de tipo Blob
      reportProgress: true,
      observe: 'events'
    }).subscribe(event => {
      if (event.type === HttpEventType.Response) {
        this.descargandoArchivo = false;

        const blob = new Blob([event.body], { type: 'application/pdf' });

        saveAs(blob, archivo.nombreOriginal ? archivo.nombreOriginal : archivo.nombre);
      }
    }, error => {
      this.descargandoArchivo = false;
    });
  }

  ayudaDescripcion(archivo: any){
    archivo.isDescripcion = !archivo.isDescripcion;
  }

  tabSeleccionado(evento){
    this.listado = evento;
  }

}
