import { PaquetesFinancieraDetalleService } from 'src/app/saldos/paquetes-financiera/paquetes-financiera-detalle/paquetes-financiera-detalle.service';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, ValidatorFn, FormArray, FormControl } from "@angular/forms";
import { FormValidation } from "../../shared/form-validation/form-validation.model";
import { FormValidationService } from "../../shared/form-validation/form-validation.service";
import { MessageService } from "../../shared/message/message.service";
import { Paquete } from "../../paquete/paquete.model";
import { PaqueteVisitasPrecios } from "../../paquete-visitas-precios/paquete-visitas-precios.model";
import { PaqueteService } from "../../paquete/paquete.service";
import { PaqueteVisitasPreciosService } from "../../paquete-visitas-precios/paquete-visitas-precios.service";
import { LenderService } from "../../lender/lender.service";
import { PaqueteFinanciera } from "../paquete-financiera.model";
// import { PaqueteFinancieraService } from "../paquete-financiera.service";
import { ApplicationService } from 'src/app/application/shared/application.service';
import { map, startWith, take } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'paquete-financiera-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
  providers: [
    FormValidationService,
    PaqueteService,
    PaqueteVisitasPreciosService,
    LenderService
  ]

})
export class PaqueteFinancieraFormComponent implements OnInit {

    @ViewChild('searchInput', { read: MatAutocompleteTrigger })
    autocompleteTrigger!: MatAutocompleteTrigger;
  
    @ViewChildren(MatExpansionPanel)
    expansionPanels!: QueryList<MatExpansionPanel>;
    
  conceptoActual: any = null;
  public conceptoSeleccionado: boolean = false;
  public mostrarProducto: boolean = false;
  public mostrarEtiquetaVisita: boolean = false;
  // public productos: any[] = [];  // Lista de productos
  // public etiquetasVisita: any[] = [];  // Lista de etiquetas de visita
  public conceptos: any[] = [];
  public conceptosProducto: any[] = [];
  public conceptosEtiquetaVisita: any[] = [];
  public conceptosCampania: any[] = [];  // Lista de conceptos de campaña
  public conceptosTemp: any[] = [];
  public cargando: boolean;
  public mostrarSaldo: boolean;
  public paquetes: Paquete[];
  public paquetesVisitasPrecios: PaqueteVisitasPrecios[];
  public paqueteFinanciera: PaqueteFinanciera = new PaqueteFinanciera();
  public items: FormArray;
  private conceptosCompletos: any[] = [];
  cargandoConcepto: boolean = false;
  public activarBoton: boolean = false;
  searchControl = new FormControl();
  filteredConceptosProducto = [];
  filteredConceptosEtiquetaVisita = [];
  filteredConceptosCampania = [];

  public form: FormGroup;
  public idPaqueteValidation: FormValidation;
  public saldoInicialValidation: FormValidation;
  public precioVisitaValidation: FormValidation;

  @Input() showAddButton: boolean = true;
  @Input() mostrarSaldoVencido: boolean = true;
  @Input() idFinanciera: number;
  @Output() save: EventEmitter<PaqueteFinanciera> = new EventEmitter();

  // private saldoDisabled = false;
  // public mostrarConcepto1: boolean = false;
  // public mostrarConcepto2: boolean = false;
  // public concepto1: any = null;
  // public concepto2: any = null;

  constructor(
    private app: ApplicationService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private validationService: FormValidationService,
    private paqueteService: PaqueteService,
    private lenderService: LenderService,
    // private paqueteFinancieraService: PaqueteFinancieraService,
    private paqueteVisitasPreciosService: PaqueteVisitasPreciosService,
    private paquetesFinancieraDetalleService: PaquetesFinancieraDetalleService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.initValidation();
    this.cargarPaquetes();
    this.searchControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      )
      .subscribe();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      'idPaquete': ['', [
        Validators.required
      ]],
      'saldoInicial': [0, [
        Validators.required,
        Validators.pattern(/^(?:[1-9]\d*|0)?(?:\.\d+)?$/),
        Validators.min(1)
      ]],
      'nombre': '',
      items: this.formBuilder.array(
        []
      )
    });
  };

  private _filter(value: any) {
    const filterValue = typeof value === 'string' ? this._normalize(value) : '';
  
    this.filteredConceptosProducto = this.conceptosProducto.filter(concepto =>
      this._normalize(concepto.nombreConcepto).includes(filterValue)
    );
  
    this.filteredConceptosEtiquetaVisita = this.conceptosEtiquetaVisita.filter(concepto =>
      this._normalize(concepto.nombreConcepto).includes(filterValue)
    );
  
    //  Nuevo filtro para campañas
    this.filteredConceptosCampania = this.conceptosCampania.filter(concepto =>
      this._normalize(concepto.nombreConcepto).includes(filterValue)
    );
  }
  

  private _normalize(text: string): string {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  }

  limpiarBusqueda() {
    this.searchControl.setValue('');
  }

  onConceptoSelected(event: any) {
    this.conceptoActual = event.option.value;
    this.agregarConcepto();

    // En el siguiente tick, colapsar los panels
    setTimeout(() => {
      this.expansionPanels.forEach(panel => panel.close());
      // Forzar que el overlay se reposicione
      this.autocompleteTrigger.updatePosition();
    }, 0);
  }

  createItem(idPaquete: number, idConcepto: number, nombreConcepto: string, precio: number): FormGroup {
    return this.formBuilder.group({
      idPaquete: idPaquete,
      idConcepto: idConcepto,
      nombre: nombreConcepto,
      precio: [precio, [
        Validators.required,
        Validators.pattern(/^(?:[1-9]\d*|0)?(?:\.\d+)?$/),
        Validators.min(1)
      ]],
      precioOriginal: [precio],  // Almacena el precio original para referencia

    });
  }

  onConceptSelect(event: any) {
    const selectedValue = event.value;
  
    if (selectedValue === 'producto') {
      this.mostrarProducto = true;
      this.mostrarEtiquetaVisita = false;
    } else if (selectedValue === 'etiquetaVisita') {
      this.mostrarProducto = false;
      this.mostrarEtiquetaVisita = true;
    } else if (selectedValue === 'campania') { 
      this.mostrarProducto = false;
      this.mostrarEtiquetaVisita = false;
      this.conceptoSeleccionado = true;
    } else if (selectedValue === 'atras') {
      this.mostrarProducto = false;
      this.mostrarEtiquetaVisita = false;
      this.conceptoSeleccionado = false;
      return;
    }
  
    this.conceptoSeleccionado = true;
  }
  

  resetConcept() {
    // Restablece el estado para permitir la adición de un nuevo concepto
    this.conceptoSeleccionado = false; // Permite mostrar de nuevo el mat-select
    this.mostrarProducto = false;
    this.mostrarEtiquetaVisita = false;
    this.conceptoActual = null;
  }

  private initValidation(): void {
    this.idPaqueteValidation = new FormValidation();
    this.idPaqueteValidation.formControl = this.form.controls['idPaquete'];
    this.idPaqueteValidation.message = {
      required: 'Información requerida.'
    };
    this.idPaqueteValidation.observeFromControl();

    this.saldoInicialValidation = new FormValidation();
    this.saldoInicialValidation.formControl = this.form.controls['saldoInicial'];
    this.saldoInicialValidation.message = {
      required: 'Información requerida.',
      pattern: 'El saldo inicial debe ser un número mayor a cero.',
      min: 'El saldo inicial debe ser un número mayor a cero.'
    };
    this.saldoInicialValidation.observeFromControl();
  }

  public submit(): void {
    if (!this.items || !this.items.length) {
      this.app.showSnackbar('¡Aviso!', 'Este paquete no tiene conceptos.', 3000, 'warning');
      return;
    }

    if (this.form.valid) {
      this.activarBoton = false;
      let paqueteFinanciera: PaqueteFinanciera = new PaqueteFinanciera();
      paqueteFinanciera = this.obtenerDatosForm();
      if (!paqueteFinanciera.precio) {
        paqueteFinanciera.precio = 0;
      }
      let loading = this.app.showLoading('Agregando paquete...');
      this.lenderService.agregarPaquete(paqueteFinanciera).subscribe(
        paquete => {
          this.limpiarFormArray();
          paqueteFinanciera.idFinancieraPaquete = paquete.idFinancieraPaquete;

          this.app.showSnackbar('¡Aviso!', 'Paquete contratado correctamente.', 3000, 'success');

          this.form.patchValue({
            idPaquete: '',
            saldoInicial: 0,
            nombre: '',
          });
          this.save.emit(paqueteFinanciera);
        },
        error => {
          this.app.hideLoading(loading);
          this.app.showError(error);
          this.messageService.httpError(error);
        },
        () => this.app.hideLoading(loading)
      );
    }
  }

  private cargarPaquetes(): void {
    this.cargando = true;
    this.paqueteService.all().subscribe(
      paquetes => {
        this.paquetes = paquetes.filter(p => this.mostrarSaldoVencido || p.idPaquete !== 0);
        this.cargando = false;
      },
      error => {
        this.cargando = false;
        this.messageService.httpError(error);
      }
    );
  }

  public limpiarFormArray() {
    while (this.items.length) {
      this.items.removeAt(0);
    }
  }

  public actualizarSaldo(value) {
    this.activarBoton = true;
    const idPaquete = this.form.controls['idPaquete'].value;

    if (!idPaquete || idPaquete <= 0) {
      this.mostrarSaldo = false;
      if (this.items?.length) {
        this.limpiarFormArray();
      }
      return;
    }

    this.mostrarSaldo = true;

    // OBTENER precio Visita usando find
    const paqueteSeleccionado = this.paquetes.find(paquete => paquete.idPaquete === value);
    if (paqueteSeleccionado) {
      this.form.controls['saldoInicial'].setValue(paqueteSeleccionado.precio);
      this.form.controls['nombre'].setValue(paqueteSeleccionado.nombre);

      if (paqueteSeleccionado.idPaquete === 0) {
        this.form.controls['saldoInicial'].disable();
      } else {
        this.form.controls['saldoInicial'].enable();
      }
    }

    this.cargandoConcepto = true;
    this.paqueteVisitasPreciosService.obtenerPaqueteVisitasPrecios(+idPaquete).subscribe(
      paquetesVisitasPrecios => {
        this.paquetesVisitasPrecios = paquetesVisitasPrecios;
        this.items = this.form.get('items') as FormArray;

        if (this.items.length) {
          this.limpiarFormArray();
        }

        this.getConceptosFinanciera();

        paquetesVisitasPrecios.forEach(paquete => {
          this.items.push(this.createItem(paquete.idPaquete, paquete.idConcepto, paquete.nombre, paquete.precio));
        });

        this.cargandoConcepto = false;
      },
      error => {
        this.cargandoConcepto = false;
        this.messageService.httpError(error);
      }
    );
  }

  getConceptosFinanciera() {
    this.paquetesFinancieraDetalleService.getConceptosEtiquetasProductos(this.idFinanciera)
      .pipe(take(1))
      .subscribe(res => {
        this.conceptosCompletos = res;

        // Filtrar conceptos
        this.filtrarConceptos(res);
      }, err => {
        this.app.showError(err);
      });
  }

  private filtrarConceptos(conceptos: any[]) {
    // Filtrar los conceptos que no están ya en paquetesVisitasPrecios
    this.conceptosTemp = conceptos.filter(concepto =>
      !this.paquetesVisitasPrecios.some(item => item.idConcepto === concepto.idConcepto)
    );
  
    this.conceptosProducto = this.conceptosTemp.filter(concepto =>
      concepto.tipoTarifa === 'producto'
    );
  
    this.conceptosEtiquetaVisita = this.conceptosTemp.filter(concepto =>
      concepto.tipoTarifa === 'etiquetaVisita'
    );
  
    this.conceptosCampania = this.conceptosTemp.filter(concepto =>
      concepto.tipoTarifa === 'campania'
    );
  
    this.filteredConceptosProducto = this.conceptosProducto;
    this.filteredConceptosEtiquetaVisita = this.conceptosEtiquetaVisita;
    this.filteredConceptosCampania = this.conceptosCampania;  
  }
  

  private obtenerDatosForm(): PaqueteFinanciera {
    let formModel = this.form.value;
    let paqueteFinanciera: PaqueteFinanciera = new PaqueteFinanciera();
    paqueteFinanciera.idFinanciera = this.idFinanciera;
    paqueteFinanciera.idPaquete = formModel.idPaquete;
    paqueteFinanciera.precio = formModel.saldoInicial;
    paqueteFinanciera.nombre = formModel.nombre;
    paqueteFinanciera.paquetesVisitasPrecios = [];

    for (let i = 0; i < this.items.length; i++) {
      let paqueteVisitaPrecio: PaqueteVisitasPrecios = new PaqueteVisitasPrecios();
      paqueteVisitaPrecio.idPaquete = this.items.controls[i].value.idPaquete;
      paqueteVisitaPrecio.precio = this.items.controls[i].value.precio;
      paqueteVisitaPrecio.idConcepto = this.items.controls[i].value.idConcepto;
      paqueteFinanciera.paquetesVisitasPrecios.push(paqueteVisitaPrecio);
    }
    return paqueteFinanciera;
  }

  agregarConcepto() {
    const precioInicial = this.conceptoActual.precioBase ?? 0;
  
    this.items.push(this.createItem(
      this.form.controls['idPaquete'].value,
      this.conceptoActual.idConcepto,
      this.conceptoActual.nombreConcepto,
      precioInicial
    ));
  
    if (this.conceptoActual.tipoTarifa === 'producto') {
      this.conceptosProducto = this.conceptosProducto.filter(concepto => concepto.idConcepto !== this.conceptoActual.idConcepto);
      this.filteredConceptosProducto = [...this.conceptosProducto];
    } else if (this.conceptoActual.tipoTarifa === 'etiquetaVisita') {
      this.conceptosEtiquetaVisita = this.conceptosEtiquetaVisita.filter(concepto => concepto.idConcepto !== this.conceptoActual.idConcepto);
      this.filteredConceptosEtiquetaVisita = [...this.conceptosEtiquetaVisita];
    } else if (this.conceptoActual.tipoTarifa === 'campania') {  // Nuevo flujo para campañas
      this.conceptosCampania = this.conceptosCampania.filter(concepto => concepto.idConcepto !== this.conceptoActual.idConcepto);
      this.filteredConceptosCampania = [...this.conceptosCampania];
    }
  
    this.resetConcept();
    this.searchControl.setValue('');
  }
  

  borrarConcepto(item: FormGroup, index: number) {
    // Recupera el concepto que se va a borrar
    const precioOriginal = item.controls['precioOriginal']?.value ?? item.controls['precio'].value;


    const conceptoBorrado = {
      idConcepto: item.controls['idConcepto'].value,
      nombreConcepto: item.controls['nombre'].value,
      precio: item.controls['precio'].value,
      precioBase: precioOriginal,  // Se conserva el precio original (base)
      tipoTarifa: this.conceptosCompletos.find(c => c.idConcepto === item.controls['idConcepto'].value)?.tipoTarifa || 'producto'
    };
  
    // Remover de items
    this.items.removeAt(index);
  
    // Volver a agregar el concepto a la lista correspondiente
    if (conceptoBorrado.tipoTarifa === 'producto') {
      this.conceptosProducto.push(conceptoBorrado);
      this.filteredConceptosProducto = [...this.conceptosProducto];
    } else if (conceptoBorrado.tipoTarifa === 'etiquetaVisita') {
      this.conceptosEtiquetaVisita.push(conceptoBorrado);
      this.filteredConceptosEtiquetaVisita = [...this.conceptosEtiquetaVisita];
    } else if (conceptoBorrado.tipoTarifa === 'campania') {
      // Agregar el concepto de campaña de nuevo a la lista
      this.conceptosCampania.push(conceptoBorrado);
      this.filteredConceptosCampania = [...this.conceptosCampania];
    }
  }
  

  public onInputLimite(index: number): void {
    if (this.items.controls[index].value.precio > 99999) {
      setTimeout(() => {
        this.items.controls[index].value.precio = 99999;
        this.items.controls[index].get('precio').patchValue(99999);
      }, 10);
    }
  }
}
