import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CobradorFacturacionPeriodo } from '../../cobradores-facturaciones-periodos/cobrador-facturacion-periodo.interface';
import { Cobrador } from '../../cobradores-movimientos/cobrador-movimiento.interface';
import { ConceptoSaldo } from '../../conceptos-saldos/conceptos-saldo.interface';
import { map, take } from 'rxjs';
import { CobradoresFacturacionesPeriodosService } from '../../cobradores-facturaciones-periodos/cobradores-facturaciones-periodos.service';
import { ConceptosSaldosService } from '../../conceptos-saldos/conceptos-saldos.service';
import { AgentService } from '@agent/agent.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { PreAgentesMovimientosService } from '../ajuste-movimiento-agentes.service';
import { PreCobradorMovimiento } from '../ajuste-movimiento-agentes.interface';

@Component({
  selector: 'app-ajuste-movimiento-agentes-nuevo',
  templateUrl: './ajuste-movimiento-agentes-nuevo.component.html',
  styleUrls: ['./ajuste-movimiento-agentes-nuevo.component.css'],
  providers: [

  ]
})
export class AjusteMovimientoAgentesNuevoComponent {

  public hijosActivos: boolean = false;
  public title = 'Agregar';
  public movimientoForm: FormGroup;
  get movimiento(): PreCobradorMovimiento {
    return this.preAgentesMovServ.movimiento;
  }

  public filtros = {
    idCobradorFacturacionPeriodo: null,
    nombrePeriodo: null,
    idCobrador: null,
    nombreCobrador: null,
    idConcepto: null,
    nombreConcepto: null,
    movStat: null,
    fechaInicio: null,
    fechaFin: null,
    page: 1,
    agenteActivo: null
  }

  conceptos: any;

  @ViewChild('periodosInput') private periodosInput: any;

  private periodoLabelSelected: string;
  public hoy = new Date();
  public isCargo: boolean;

  @ViewChild('cobradoresInput') private cobradoresInput: any;

  private cobradorLabelSelected: string;

  @ViewChild('conceptosInput') private conceptosInput: any;

  private conceptoLabelSelected: string;

  constructor(
    public preAgentesMovServ: PreAgentesMovimientosService,
    private fb: FormBuilder,
    private app: ApplicationService,
    private agenteServ: AgentService,
    private periodoServ: CobradoresFacturacionesPeriodosService,
    private conceptosServ: ConceptosSaldosService,


  ) {

    this.movimientoForm = this.fb.group(
      {
        concepto: [null, []],
        idCobradorFacturacionPeriodo: ['', Validators.required],
        idCobrador: ['', Validators.required],
        idConcepto: ['', Validators.required],
        isCargo: [''],
        nombreMovimiento: ['', Validators.required],
        monto: ['', [Validators.required, Validators.pattern(/^(0|[1-9]\d*)(\.\d+)?$/)]]
      }
    );

  }



  checkControl(control: string) {
    return this.movimientoForm.controls[control].invalid && this.movimientoForm.controls[control].touched;
  }

  getErrorMessage(control: string) {
    return this.movimientoForm.controls[control].hasError('required') ? 'Este campo es requerido.' :
      this.movimientoForm.controls[control].hasError('pattern') ? 'Solamente valores numéricos' : '';
  }

  onPeriodoSelected(periodo: CobradorFacturacionPeriodo) {
    this.movimientoForm.get('idCobradorFacturacionPeriodo').value;

  }

  resetPeriodo() {
    this.movimientoForm.get('idCobradorFacturacionPeriodo').reset();
    this.periodosInput._element.nativeElement.firstChild.firstChild.value = '';
    this.periodoLabelSelected = undefined;
  }

  public periodosSearch = function (term: string) {

    const query = `?nombre=${term}`;

    return this.periodoServ.obtenerPeriodos(query)
      .pipe(
        take(1),
        map((res: any) => res.data.map(

          ({ idCobradorFacturacionPeriodo, nombre }) => (
            { idCobradorFacturacionPeriodo: idCobradorFacturacionPeriodo, nombre: nombre }
          )
        ))
      );
  }.bind(this);

  public agenteFilterSearch = function (term: string) {
    return this.agenteServ.obtenerAgentes(term, null)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idCobrador, nombreCompleto, idAgente }) => (
            { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}`, idAgente: idAgente }
          )
        ))
      );
  }.bind(this);

  public buscarConceptos = function (term: string) {

    if (this.conceptosFilterFlag) {
      this.conceptosFilterFlag = false;
      return this.conceptosFilterFounded;
    }
    return this.conceptosServ.buscarConceptos(term)
      .pipe(
        take(1),
        map((res: any) => res.data.map(
          ({ idConcepto, nombre, idTipoConcepto, cargo }) => (
            { idConcepto: idConcepto, nombre: nombre, idTipoConcepto: idTipoConcepto, cargo: cargo }
          )
        ))
      );
  }.bind(this);



  onCobradorSelected(cobrador: Cobrador) {
    this.movimientoForm.get('idCobrador').patchValue(cobrador.idCobrador);
    this.cobradoresInput._element.nativeElement.firstChild.firstChild.value = cobrador.nombre;
    this.cobradorLabelSelected = cobrador.nombre;
  }

  resetCobrador() {
    this.movimientoForm.get('idCobrador').reset();
    this.cobradoresInput._element.nativeElement.firstChild.firstChild.value = '';
    this.cobradorLabelSelected = undefined;
  }

  onConceptoSelected(concepto: ConceptoSaldo) {
    this.isCargo = concepto ? concepto.cargo : undefined;
    this.movimientoForm.get('idConcepto').patchValue(concepto ? concepto.idConcepto : null);
    this.movimientoForm.get('nombreMovimiento').patchValue(concepto ? concepto.nombre : null);
    this.movimientoForm.get('isCargo').patchValue(this.isCargo);

  }

  resetConcepto() {
    this.isCargo = undefined;

    this.movimientoForm.get('idConcepto').reset();
    this.conceptosInput._element.nativeElement.firstChild.firstChild.value = '';
    this.conceptoLabelSelected = undefined;
  }

  onSubmit() {
    // Si el formulario es inválido, marcar todos los controles como touched y mostrar mensaje
    if (this.movimientoForm.invalid) {
      Object.keys(this.movimientoForm.controls).forEach(key => {
        this.movimientoForm.get(key).markAsTouched();
      });
      this.app.showSnackbar('¡Aviso!', 'Por favor, completa todos los campos requeridos.', 3000, 'warning');
      return;
    }
  
    // Validar que el monto sea mayor a cero
    const montoValue = this.movimientoForm.get('monto').value;
    if (montoValue && +montoValue <= 0) {
      this.app.showSnackbar('¡Aviso!', 'El monto debe ser mayor a cero.', 3000, 'warning');
      return;
    }
  
    // Si todas las validaciones pasan, proceder con el guardado
    this.insert();
  }
  

  insert() {
    this.preAgentesMovServ.agregarMovimiento(this.movimientoForm.value)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        // console.log(res.success)

        if (res.success) {
          this.app.showSnackbar('¡Aviso!', 'Movimiento agregado correctamente.', 3000, 'success');
          this.movimientoForm.reset()
        } else {
          this.app.showSnackbar('¡Aviso!', 'Error al guardar, revisar los datos.', 3000, 'success');
        }


      }, (error: any) => {
        this.app.showError(error);
      });
  }



}
