import { Params } from '@angular/router';

import { Injectable } from '@angular/core';
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Pagination } from "../pagination/pagination.model";
import { Visit } from "./visit.model";
import { Revisita } from "./revisita/models/revisita.model";
import { EmailCollected } from "../email-collected/email-collected.model";
import { PhoneCollected } from "../phone-collected/phone-collected.model";
import { VisitPaginator } from "./visit.paginator";
import { RevisitaPaginator } from "./revisita/paginators/revisita.paginator";
import { ServicesKobraService } from "../core/http/services-kobra/services-kobra.service";
import { ApiKobraService } from "../core/http/api-kobra/api-kobra.service";
import { map, catchError } from "rxjs/operators";
import { ServiciosKobraService } from '../core/http/servicios-kobra/servicios-kobra.service';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';
import { VisitPhoto } from '../visit-photo/visit-photo.model';
import { ServiciosKobraResponse } from '@servicios/servicios-kobra-response';
import { environmentSelector } from 'src/environments/environment.selector';
import { CalificacionVisita } from '../calificaciones-visita/calificacion-visita';
import { Account } from "../account/account.model";
import { ClientAddress } from '../client-address/client-addres.model';
import { AccountPaginator } from '../account/account.paginator';
import { StorageKobraService } from 'src/app/core/http/storage-kobra/storage-kobra.service';
import { SessionData } from '../shared/interfaces/session-data';
import { LocalStorageService } from '../shared/services/local-storage.service';
import { Client } from '../client/client.model';
import { Financiera } from '../financieras/shared/financiera.model';
import { GrupoVariable } from '../catalogos/checklists/interfaces/variables/variables.interface';
import { IServicesTypeKobraResponse } from '../core/http/servicio-general/servicio-general-response';

@Injectable()
export class VisitService {
  baseURL: string = environmentSelector().kobraServices.visitas;

  private apiService: string = '/v2/visits';
  private serviceURL: string = '/visitas';
  private resource: string = '/visitas';
  private leanServicesURL = '/visitas';
  sessionData: SessionData;


  constructor(
    private apiKobra: ApiKobraService,
    private servicesKobra: ServicesKobraService,
    private serviciosKobra: ServiciosKobraService,
    private serviciosVisitas: ServicesVisitasService,
    private httpClient: HttpClient,
    private storageKobraSercice: StorageKobraService,
    private localStorageService: LocalStorageService

  ) {
    this.sessionData = this.localStorageService.getSessionData();
  }

  private headers(): HttpHeaders {
    let header: any = {'Content-Type': 'application/json'};
    let token = localStorage.getItem('access-token');
    if (token) {
      header['Authorization'] = token;
    }
    let paisSeleccionado = localStorage.getItem('paisSeleccionado');
    if(paisSeleccionado){
        header['Country'] = JSON.parse(paisSeleccionado).abreviacion;
    }
    return new HttpHeaders(header);
  }

  private request(req: Observable<any>, login: boolean = false): Observable<ServiciosKobraResponse> {
    return req.pipe(
      map(data => {
        let res = <ServiciosKobraResponse> data;
        if(!res.success && !res.data) {
          throw res.error.message;
        }
        return res;
      }),
      catchError((err: HttpErrorResponse|string) => {
        var message;
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            message = err.error.message;
          } else {

            if (err.status == 401) { // Session has expired
              localStorage.clear();
              sessionStorage.clear();

              if (err.error && err.error.error && err.error.error.message) {
                message = err.error.error.message;
              }

              location.href = '/auth';
            } else if (err.error && err.error.error && err.error.error.message) {
              message = err.error.error.message;
            } else if (err.status == 404) {
              message = 'Error 404: not found.';
            } else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              console.error(
                `Backend returned code ${err.status}, ` +
                `body was: ${err.message}`);
              message = err.message;
            }
          }
        } else {
          message = err;
        }
        return throwError(message);
      })
    );
  }

  public delete( path: string ): Observable<ServiciosKobraResponse> {
    return this.request(this.httpClient.delete(this.baseURL + path, {headers: this.headers()}));
  }

  public get( path: string, descartarVisitasUri: boolean = false ): Observable<any> {
    //return this.request(this.httpClient.get(this.baseURL + path, {headers: this.headers()}));
    let url = ( descartarVisitasUri ? '' : `${this.serviceURL}` ) + `${path}`;
    return this.serviciosVisitas.get(url).pipe(
      map(res => res.data)
    );
  }

  public patch( path: string, data: any ): Observable<ServiciosKobraResponse> {
    return this.request(this.httpClient.patch(this.baseURL + path, data, {headers: this.headers()}));
  }

  public post( path: string, data: any ): Observable<any> {
    return this.serviciosVisitas.post(`${this.serviceURL}` + `${path}` , data).pipe(
      map(res => res.data)
    );
  }

  public cancel( visitID: number ): Observable<Visit> {
    return this.servicesKobra.post(`${this.serviceURL}/${visitID}/cancelar-movimientos`, null).pipe(
      map(res => <Visit> res.data)
    );
  }

  public getCheckList( idCliente: number, idProducto: number ): Observable<any> {
    let url = `/clientes/${idCliente}/producto/${idProducto}/checklist`;
    return this.serviciosVisitas.get(url).pipe(
      map(res => res.data)
    );
  }

  public createChecklist (id:number, data: any): Observable<any> {
    return this.serviciosKobra.post(`${this.serviceURL}/${id}` + '/checklist', data).pipe(
      map(res => res.data)
    );
  }

  public finishVisit (id:number, data: any): Observable<any> {
    return this.serviciosKobra.post(`${this.serviceURL}/${id}` + '/finalizar', data).pipe(
        map(res => res.data)
      );
  }

  public getOne( id: string, query?:string ): Observable<Visit> {
    let url = `${this.serviceURL}/${id}`;
    if (query) {
      url += query;
    }
    return this.serviciosVisitas.get(url).pipe(
      map(res => <Visit> res.data)
    );
  }

  public obtenerEstatus(idCliente: number, idAcreditado: number, idCuenta: number, idAcreditadoCuentaDomicilio: number ): Observable<any> {
    let url = `/acreditados/${idAcreditado}/info-estatus?idCliente=${idCliente}&idCuenta=${idCuenta}&idAcreditadoCuentaDomicilio=${idAcreditadoCuentaDomicilio}`;
    return this.serviciosVisitas.get(url).pipe(
      map(res => {
        return res.data;
      })
    );
  }

  public obtenerIdCuentaPorIdExterno(idExterno,idCliente,subIdExterno){
    return this.serviciosVisitas.get( `/cuenta?idExterno=${idExterno}&idCliente=${idCliente}${subIdExterno?'&subIdExterno='+subIdExterno:''}`);
  }

  public oneDetail(id: number): Observable<Account> {
    return this.serviciosVisitas.get(`/cuentas/${id}`).pipe(
      map(res => {
        let account: Account = Account.map(res.data);
        return account;
      })
    );
  }

  obtenerDetallesCuentaDinamico(idCliente: any, idAcreditado: any, idCuenta: number) {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/acreditados/detalle-cuenta?idExterno=${idAcreditado}&idCuenta=${idCuenta}`);
  }

  public getLast( id: number, query?:string ): Observable<Visit> {
    let url = `${this.serviceURL}/${id}`;
    if (query) {
      url += query;
    }
    return this.serviciosVisitas.get(url).pipe(
      map(res => <Visit> res.data)
    );
  }

  public getAdminRate( id: number, query: string ): Observable<number> {
    return this.servicesKobra.get(`${this.serviceURL}/${id}/calificacion?tipo=administrador`).pipe(
      map(res => res.data.calificacion)
    );
  }

  public getEmailsCollected(visitID: number, idAgent: number, idCliente: number, idFinanciera: number): Observable<EmailCollected[]> {
    return this.serviciosVisitas.get(`${this.resource}/${visitID}/emails-recopilados?idAgent=`+idAgent+'&idFinanciera='+idFinanciera+'&idCliente='+idCliente).pipe(
      map(res => EmailCollected.mapArrayData(res.data))
    );
  }

  public getPhonesCollected(visitID: number, idAgent: number, idCliente: number, idFinanciera: number): Observable<PhoneCollected[]> {
    return this.serviciosVisitas.get(`${this.resource}/${visitID}/telefonos-recopilados?idAgent=`+idAgent+'&idFinanciera='+idFinanciera+'&idCliente=' + idCliente).pipe(
      map(res => PhoneCollected.mapArrayData(res.data))
    );
  }

  public loadPhotosCollected(visitID: number, idAgent: number, idCliente: number, idFinanciera: number): Observable<VisitPhoto[]> {
    return this.serviciosVisitas.get(`${this.resource}/${visitID}/fotos?idAgent=`+idAgent+'&idCliente='+idCliente+'&idFinanciera='+idFinanciera).pipe(
      map(res => VisitPhoto.mapArrayData(res.data))
    );
  }



  public search( pagination: Pagination, extraParams: string): Observable<VisitPaginator> {
    let query: string = '/search?page=' + pagination.nextPage + extraParams;

    return this.apiKobra.get(this.apiService + query).pipe(
      map(res => {
        let response: any = res;
        let visitPaginator: VisitPaginator = new VisitPaginator();
        visitPaginator.pagination = new Pagination();
        visitPaginator.pagination.currentPage = response.pagination.currentPage;
        visitPaginator.pagination.pageCount = response.pagination.pageCount;
        visitPaginator.pagination.perPage = response.pagination.perPage;
        visitPaginator.pagination.totalCount = response.pagination.totalCount;
        visitPaginator.data = [];
        for (let i = 0; i < response.data.length; i++) {
          let visit: Visit = new Visit();
          //visit.id = response.data[i].visitID;
          visit.folio = response.data[i].visitFolio;
          visit.cancellationReason = response.data[i].visitCancellationReason;
          visit.cancelled = response.data[i].visitCancelled == 1;
          visit.createdAt = response.data[i].visitCreatedAt;
          visit.account.id = response.data[i].accountID;
          visit.account.createdAt = response.data[i].accountCreatedAt;
          visit.account.product.id = response.data[i].productID;
          visit.account.product.name = response.data[i].productName;
          visit.agent.id = response.data[i].agentID;
          visit.agent.fullName = response.data[i].agentFullName;
          visit.client.idAcreditado = response.data[i].clientID;
          visit.client.fullName = response.data[i].clientFullName;
          visit.client.externalID = response.data[i].externalID;
          visit.clientAddress.id = response.data[i].clientAddressID;
          visit.clientAddress.location = response.data[i].clientAddressLocation;
          visit.clientAddress.zipCode = response.pageData[i].zipCode;
          visit.generalChecklist.id = response.data[i].generalChecklistID;
          visit.generalChecklist.createdAt = response.data[i].generalChecklistCreatedAt;
          visit.generalChecklist.comment = (response.data[i].generalChecklistComment ? response.data[i].generalChecklistComment :  response.data[i].visitCancellationReason);
          visit.generalChecklist.debtCollectionChecklist.id =
            response.data[i].debtCollectionChecklistID;
          visit.generalChecklist.debtCollectionChecklist.paymentPromise =
            response.data[i].debtCollectionChecklistPaymentPromise;
          visit.generalChecklist.debtCollectionChecklist.paymentPromiseAmount =
            response.data[i].debtCollectionChecklistPaymentPromiseAmount;
          visit.lender.id = response.data[i].lenderID;
          visit.lender.name = response.data[i].lenderName;
          visit.rebound = +response.data[i].rebound ? true:false;
          visit.generalChecklist.mockLocation = +response.data[i].mockLocation ? true:false;
          visit.visibility = response.data[i].visibility;
          visit.calificacionAdministradorFinanciera = response.data[i].calificacionAdministradorFinanciera ;
          visit.calificacionSuperAdministrador = response.data[i].calificacionSuperAdministrador ;
          visit.latInicio = response.data[i].latInicio;
          visit.latFin = response.data[i].latFin;
          visit.lngInicio = response.data[i].lngInicio;
          visit.lngFin = response.data[i].lngFin;
          visit.latFotos = response.data[i].latFotos;
          visit.lngFotos = response.data[i].lngFotos;
          visit.coordenadas = response.data[i].latFotos ? response.data[i].latFotos + ', ' + response.data[i].lngFotos : response.data[i].latInicio + ', ' + response.data[i].lngInicio;

          visitPaginator.data.push(visit);
        }
        return visitPaginator;
      })
    );
  }


  public obtenerListado( pagination: Pagination, extraParams: string, rows: number): Observable<VisitPaginator> {
    let start = 0;

    if (pagination.nextPage > 1) {
      start = ((pagination.nextPage - 1) * rows) + 1;
    }
    let query: string = '/visitas-todas-grid?start=' + start + extraParams;

    return this.serviciosVisitas.get(query).pipe(
      map(res => {
        let response: any = res.data;

        let visitPaginator: VisitPaginator = new VisitPaginator();
          visitPaginator.pagination = new Pagination();
          visitPaginator.pagination.currentPage = response.page ? response.page : 1;
          visitPaginator.pagination.pageCount = response.pages;
          visitPaginator.pagination.perPage = rows;
          visitPaginator.pagination.totalCount = response.totalRows;
          visitPaginator.data = [];

        for (let i = 0; i < response.pageData.length; i++) {
          let visit: Visit = new Visit();
          let queryString = '';
          visit.id = response.pageData[i].idVisita;
          visit.idFinanciera = response.pageData[i].idFinanciera;
          visit.folio = response.pageData[i].folioVisita;
          visit.grupo = response.pageData[i].grupo;
          visit.sucursal = response.pageData[i].sucursal;
          visit.cancelled = response.pageData[i].cancelada;
          visit.createdAt = response.pageData[i].inicioVisita;
          visit.fecInicio = response.pageData[i].inicioVisita;
          visit.account.id = response.pageData[i].idCuenta;
          visit.account.createdAt = response.pageData[i].creacionCuenta;
          visit.account.product.id = response.pageData[i].productID;
          visit.account.product.name = response.pageData[i].tipoProducto;
          visit.agent.id = response.pageData[i].idCobrador;
          visit.agent.fullName = `${response.pageData[i].idCobrador} - ${response.pageData[i].nombreCobrador}`;
          visit.client.idAcreditado = response.pageData[i].idAcreditado;
          visit.client.activo = response.pageData[i].clienteActivo;
          visit.client.fullName = response.pageData[i].nombreAcreditado;
          visit.client.externalID = response.pageData[i].idExterno;
          visit.client.subExternalID = response.pageData[i].subIdExterno;
          visit.clientAddress.municipality = response.pageData[i].municipio;
          visit.clientAddress.state = response.pageData[i].estado;
          visit.clientAddress.zipCode = response.pageData[i].codigoPostal;
          visit.generalChecklist.id = response.pageData[i].idVisita;
          visit.generalChecklist.createdAt = response.pageData[i].finVisita;
          visit.fecFin = response.pageData[i].finVisita;
          visit.generalChecklist.comment = response.pageData[i].comentarios;
          visit.generalChecklist.debtCollectionChecklist.id = response.pageData[i].debtCollectionChecklistID;
          visit.generalChecklist.debtCollectionChecklist.paymentPromise = response.pageData[i].promesaPago === null ? null : ( +response.pageData[i].promesaPago ? true:false );
          visit.lender.name = response.pageData[i].nombreCliente;
          visit.rebound = response.pageData[i].repunteo ? true:false;
          visit.generalChecklist.mockLocation = +response.pageData[i].mock ? true:false;
          visit.generalChecklist.offline = +response.pageData[i].offline ? true:false;
          visit.visibility = response.pageData[i].agenteInterno ? 'Sí' : 'No';
          visit.calificacionAdministradorFinanciera = response.pageData[i].calificacionAdministradorFinanciera ? response.pageData[i].calificacionAdministradorFinanciera : 'No' ;
          visit.calificacionSuperAdministrador = response.pageData[i].calificacionSuperAdministrador ? response.pageData[i].calificacionSuperAdministrador : 'No';
          visit.nombreEtiqueta = response.pageData[i].nombreEtiqueta;
          visit.idAgenteM = response.pageData[i].idAgente;
          visit.latInicio = response.pageData[i].latInicio;
          visit.lngInicio = response.pageData[i].lngInicio;
          visit.latFin = response.pageData[i].latFin;
          visit.lngFin = response.pageData[i].lngFin;
          visit.latFotos = response.pageData[i].latFotos;
          visit.lngFotos = response.pageData[i].lngFotos;
          //visit.telCel = response.pageData[i].telCelVisita;
          visit.dispositivo = response.pageData[i].dispositivo;
          visit.estado = response.pageData[i].estado;
          visit.municipio = response.pageData[i].municipio;
          visit.calle = response.pageData[i].calle;
          visit.colonia = response.pageData[i].colonia;
          visit.codigoPostal = response.pageData[i].codigoPostal || null;
          visit.numeroExt = response.pageData[i].numeroExt;
          visit.numeroInt = response.pageData[i].numeroInt || '';
          visit.fullAddres =  response.pageData[i].domicilioCompleto ? response.pageData[i].domicilioCompleto : (response.pageData[i].calle ? response.pageData[i].calle +' ' : '') +
                              (response.pageData[i].numeroExt ? response.pageData[i].numeroExt +' ' : '') +
                              (response.pageData[i].numeroInt ? response.pageData[i].numeroInt +', ' : '') +
                              (response.pageData[i].colonia ? response.pageData[i].colonia +' ' : '') +
                              (response.pageData[i].colonia ? response.pageData[i].codigoPostal +', ' : '') +
                              (response.pageData[i].municipio ? response.pageData[i].municipio+', ' : '') +
                              (response.pageData[i].estado ? response.pageData[i].estado +'.' : '') ;

          if(response.pageData[i].domicilioCompleto){
              visit.clientAddress.zipCode = '';
              visit.clientAddress.municipality = '';
              visit.clientAddress.state = '';
              visit.clientAddress.suburb = '';
          }
          visit.clientAddress.folioDomicilio = response.pageData[i].folioDomicilio;

          queryString = response.pageData[i].subIdExterno != null ? `&subIdExterno=${response.pageData[i].subIdExterno}` : "";

          if(this.sessionData.isSuperAdmin){
              queryString += "&idCliente="  + response.pageData[i].idCliente;
          };

          visit.account.urlDetalleCuenta = `?idExterno=${response.pageData[i].idExterno}${queryString}`;
          visitPaginator.data.push(visit);
          visit.nombreEtiquetaCuenta = response.pageData[i].nombreEtiquetaCuenta;
          visit.nombreEtiquetaOperacion = response.pageData[i].nombreEtiquetaOperacion;
          visit.coordenadasVisita = response.pageData[i].coordenadasVisita ? JSON.parse(response.pageData[i].coordenadasVisita) : [];
          //visit.tiposCoordenada = response.pageData[i].tiposCoordenada ? JSON.parse(response.pageData[i].tiposCoordenada) : [];
          Visit.ajustarCoordenadasPendientes(/*visit.tiposCoordenada, */visit.coordenadasVisita);
          visit.coordenadas = visit.latFotos ? visit.latFotos + ', ' + visit.lngFotos
                            : visit.latInicio + ', ' + visit.lngInicio

        }
        return visitPaginator;
      })
    );
  }

  public obtenerVisitasMapa(start: number, rows: number, page: number, query = '') {
    query+= `&start=${start}&rows=${rows}&page=${page}`;
    let uri: string = '/visitas-todas-grid?' + query;

    return this.serviciosVisitas.get(uri).pipe(
        map(res => {
            return res.data;
        })
    );
}


  public obtenerCalificaciones(idCliente: number, idAgente: number, visitID: number, folio: string ): Observable<CalificacionVisita[]> {
    return this.serviciosVisitas.get(`${this.serviceURL}/${visitID}/calificaciones?idCliente=${idCliente}&idAgente=${idAgente}&folio=${folio}`).pipe(
      map((res) => <CalificacionVisita[]> res.data)
    );
  }

  public visits(id: number, idFinanciera: number): Observable<any> {
    var query: string = '';
    if(idFinanciera){
      query = `?idFinanciera=${idFinanciera}`;
    }
    return this.serviciosVisitas.get(`/cuentas/${id}/historial-visitas${query}&finalizada=true&cancelada=false&mock=false`).pipe(
      map(res => {
        let visits = res.data;
        res.data.forEach((visit, index) => {
          visits[index].nombreAgenteCompleto = `${visit.idCobrador}${visit.nombreAgente ? ' - ' + visit.nombreAgente : ''}${visit.apellidoPatAgente ? ' ' + visit.apellidoPatAgente : ''}${visit.apellidoMatAgente ? ' ' + visit.apellidoMatAgente : ''}`;
        });
        return visits;
      })
    );
  }

  public getOneArchivosAcreditados(query?:string ): Observable<any> {
    let url = `/acreditados-archivos`;
    if (query) {
      url += query;
    }
    return this.serviciosVisitas.get(url).pipe(
      map(res => <any> res.data)
    );
  }

  public getArchivosAcreditados(query?:string ): Observable<any> {
    let url = `/acreditados-archivos`;
    if (query) {
      url += query;
    }
    return this.serviciosVisitas.get(url);
  }

  public obtenerContratos(idCliente: number, query?:string ): Observable<any> {
    let url = `/clientes/${idCliente}/cuentas-contratos`;
    if (query) {
      url += query;
    }
    return this.serviciosVisitas.get(url);
  }

  public eliminarContrato(idCliente: number, idAcreditado: number, idCuenta: number, idAcreditadoArchivo: number) {
    const params = `idAcreditado=${idAcreditado}&idCuenta=${idCuenta}&idAcreditadoArchivo=${idAcreditadoArchivo}`;

    return this.serviciosVisitas.delete(`/clientes/${idCliente}/cuentas-contratos?${params}`);
  }

  public obtenerMetodosDePago(): Observable<any> {
    return this.serviciosVisitas.get(`/visitas-metodos-pago`).pipe(
      map((res) => <any> res.data)
    );
  }

  public obtenerEtiquetasClienteProducto(filters: any): Observable<any> {
    let idCliente = filters.idCliente || '';
    let idProducto = filters.idProducto || '';
    let estatus = filters.estatus || '';

    const campos = '"idCliente", "cliente", "idProducto", "producto", "idEtiquetaVisita", "etiquetaVisita", "activo"';
    if (idCliente != '') {
      idCliente = `&idCliente=${filters.idCliente}`;
    }

    if (idProducto != '') {
      idProducto = `&idProducto=${filters.idProducto}`;
    }

    if (estatus != '') {
      estatus = `&activo=${filters.estatus}`;
    }

    let queryParams = `?stl=${campos}${idCliente}${idProducto}${estatus}`;

    return this.serviciosVisitas.get(`/clientes/clientes-etiquetas-visitas${queryParams}`).pipe(
      map((res) => <any> res.data)
    );
  }

  public editEtiquetas( idVisita: number, params: any ): Observable<any> {
    let uri = `${this.serviceURL}/${idVisita}/etiqueta-visita`
    return this.serviciosVisitas.patch(uri, params).pipe(
        map(res => {
            return res;
        })
    );
  }

  obtenerHistorialMensajes(idCliente:any, idAcreditado:any, idCuentaPG:number) {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/acreditados/${idAcreditado}/mensajes?idCuenta=${idCuentaPG}`);
  }

  public obtenerRevisitas( pagination: Pagination, extraParams: string, rows: number): Observable<RevisitaPaginator> {
    let start = 0;

    if (pagination.nextPage > 1) {
      start = ((pagination.nextPage - 1) * rows) + 1;
    }
    let query: string = '/visitas-solicitudes-revisita-grid?start=' + start + extraParams;

    return this.serviciosVisitas.get(query).pipe(
      map(res => {
        let response: any = res.data;

        let visitPaginator: RevisitaPaginator = new RevisitaPaginator();
          visitPaginator.pagination = new Pagination();
          visitPaginator.pagination.currentPage = response.page ? response.page : 1;
          visitPaginator.pagination.pageCount = response.pages;
          visitPaginator.pagination.perPage = rows;
          visitPaginator.pagination.totalCount = response.totalRows;
          visitPaginator.data = [];

          visitPaginator.data = Revisita.mapArray(response.pageData);
        return visitPaginator;
      })
    );
  }

  public obtenerDomicilios(idCliente: number, idAcreditado: number, idCuenta: number, primary: boolean) {
    return this.serviciosVisitas.get(`/acreditados/domicilios?idCliente=${idCliente}&idAcreditado=${idAcreditado}&idCuenta=${idCuenta}&primary=${primary}`);
  }

  public obtenerUltimaVisita(idCliente: number, idAcreditado: number, idCuenta: number) {
    return this.serviciosVisitas.get(`/acreditados/${idAcreditado}/cuentas/${idCuenta}/ultima-visita?idCliente=${idCliente}`);
  }

  public obtenerDomicilioAcreditado(idCliente: number, idAcreditado: number, idCuenta: number, idDomicilioAcreditado: number, idAcreditadoCuentaDomicilio: number) {
    let uri = `/acreditados/domicilios?idCliente=${idCliente}&idAcreditado=${idAcreditado}&idCuenta=${idCuenta}&idDomicilioAcreditado=${idDomicilioAcreditado}&idAcreditadoCuentaDomicilio=${idAcreditadoCuentaDomicilio}`;
    return this.serviciosVisitas.get(uri);
  }

  public descargarVisitaConCuestionario(params:string){
    return this.serviciosVisitas.get( `/visitas-grid-download${params}`);
  }

  public descargarPromesasDePago(params:string){
    return this.serviciosVisitas.get( `/promesas-pago-grid-download${params}`);
  }

  public obtenerEtiquetasCuenta(idCliente:any){
    return this.serviciosVisitas.get( `/etiquetas-cuentas?idCliente=${idCliente}`);
  }

  public obtenerEtiquetasOperaciones(){
    return this.serviciosVisitas.get( `/etiquetas-operaciones`)
    .pipe(
      map((res) => {
        return res.data as any;
      })
    );
  }


  public client(idCuenta: number): Observable<Client> {
    return this.serviciosVisitas.get(`/cuentas/${idCuenta}/acreditado`).pipe(
      map(res => {
        let client: Client = Client.map(res.data);
        return client;
      })
    );
  }

  public obtenerHistorialEtiquetasCuenta(idCliente:any,idCuenta:any){
    return this.serviciosVisitas.get( `/etiquetas-cuentas-historial?idCliente=${idCliente}&idCuenta=${idCuenta}`);
  }

  public obtenerEstados(){
    return  this.serviciosVisitas.get('/estados');
  }

  public obtenerMunicipios(idEstado: number){
    return  this.serviciosVisitas.get(`/estados/${idEstado}/municipios`);
  }
  public obtenerColonias(municipioId: number, codigoPostal:number ){
    return  this.serviciosVisitas.get(`/municipios/${municipioId}/colonias?codigoPostal=${codigoPostal}`);
  }
  public obtenerCodigosPostales(municipioId: number){
    return  this.serviciosVisitas.get(`/municipios/${municipioId}/codigos-postales`);
  }

  public cargarHistorialPagosRecabados(params: string){
    return this.serviciosVisitas.get( `/historial-pagos-recabados${params}`);
  }

  public obtenerAcreditados(idAcreditado: number,idCliente:any){
    return this.serviciosVisitas.get( `/acreditados-domicilios?idAcreditado=${idAcreditado}&idCliente=${idCliente}`);
  }

  public obtenerCuentasDesasignadas(idAgente: number, parametros:String){
    return this.serviciosVisitas.get( `/cuentas-desasignados/agentes/${idAgente}${parametros}`);
  }

  public descargarExcelCuentasDesasignadas(idAgente:number, parametros:String){
    return this.serviciosVisitas.get( `/excel-cuentas-desasignados/agentes/${idAgente}${parametros}`);
  }

  public obtenerCorreosElectronicos(params:string){
    return this.serviciosVisitas.get( `/visitas-informacion-recopilada-correos-grid${params}`);
  }

  public obtenerTelefonos(params:string){
    return this.serviciosVisitas.get( `/visitas-informacion-recopilada-telefonos-grid${params}`);
  }

  obtenerCuentasMapa(start: any, rows: any,query = '') {
    query = query? (query+= `start=${start}&rows=${rows}`):`?start=${start}&rows=${rows}`;
    return this.serviciosVisitas.get(`/cuentas-mapa${query}`);
  }

  public asignarCuentaAgente(cuentas: any, agentes: any, idCliente: number): Observable<any> {
        let data: any = {
            idCliente: idCliente,
            agentes: agentes,
            cuentas: cuentas
        };

        return this.serviciosVisitas.post(`/agentes/asignar-visibilidad`, data).pipe(
            map(res => {
                let response: any = {
                    error: res.error,
                    data: res.data,
                    success: res.success
                }
                return response;
            })
        );
    }

    public cargarCuentas(idCliente:number ,filtros: string, pagination: Pagination, rows: number): Observable<any> {
      let parametros:string = '';
      if(filtros) {
        parametros = `?${filtros}`;
      }

      let start = 0;

      if (pagination.nextPage > 1) {
          start = ((pagination.nextPage - 1) * rows) + 1;
      }

      let query: string = `/clientes/${idCliente}/cuentas-visibles?start=` + start + parametros;

      return this.serviciosVisitas.get(query).pipe(
        map(res => {

          let response: any = res.data;
          let accountPaginator: AccountPaginator = new AccountPaginator();
          accountPaginator.pagination = new Pagination();
          accountPaginator.pagination.currentPage = response.page ? response.page : 1;
          accountPaginator.pagination.pageCount = response.pages;
          accountPaginator.pagination.perPage = rows;
          accountPaginator.pagination.totalCount = response.totalRows;
          accountPaginator.data = [];

          for (let i = 0; i < response.pageData.length; i++) {
            let cuentas: any= {};
            cuentas.idCliente = response.pageData[i].idCliente;
            cuentas.idFinanciera = response.pageData[i].idFinanciera;
            cuentas.nombreCliente = response.pageData[i].nombreCliente;
            cuentas.nombreProducto = response.pageData[i].nombreProducto;
            cuentas.idAcreditado = response.pageData[i].idAcreditado;
            cuentas.idCuenta = response.pageData[i].idCuenta;
            cuentas.nombreAcreditado = response.pageData[i].nombreAcreditado;
            cuentas.idExterno = response.pageData[i].idExterno;
            cuentas.subIdExterno = response.pageData[i].subIdExterno;
            cuentas.fechaRegistro = response.pageData[i].fechaRegistro;
            cuentas.idAgente = response.pageData[i].idAgente;
            cuentas.nombreAgente = response.pageData[i].idCobrador + ' - ' + response.pageData[i].nombreAgente;
            cuentas.sucursal = response.pageData[i].sucursal;
            cuentas.grupo = response.pageData[i].grupo;
            cuentas.idCobrador = response.pageData[i].idCobrador;
            cuentas.folioDomicilio = response.pageData[i].folioDomicilio;
            cuentas.domicilio = (response.pageData[i].calle ? response.pageData[i].calle +' ' : '') +
                                (response.pageData[i].numeroExt ? response.pageData[i].numeroExt +', ' : '') +
                                (response.pageData[i].numeroInt ? response.pageData[i].numeroInt +', ' : '') +
                                (response.pageData[i].colonia ? response.pageData[i].colonia +' ' : '') +
                                (response.pageData[i].colonia ? response.pageData[i].codigoPostal +', ' : '') +
                                (response.pageData[i].municipio ? response.pageData[i].municipio+', ' : '') +
                                (response.pageData[i].estado ? response.pageData[i].estado +'.' : '') ;
            accountPaginator.data.push(cuentas);
        }

          return accountPaginator;
        })
      );

    }

    public eliminarCuentasVisibles(idCliente: number, cuentas: any): Observable<any> {

      let data: any = {
        idCliente: idCliente,
        cuentas: cuentas
      };

      return this.serviciosVisitas.patch(`/clientes/${idCliente}/agentes/vibilidad-cuentas`, data);
  }

  public obtienerVariable(idCliente: number, idCheckList: number, numeroVersion, idProducto: number, idTipoFormato:number): Observable<IServicesTypeKobraResponse<GrupoVariable[]>> {
    return this.serviciosVisitas.get<GrupoVariable[]>(`/clientes/${idCliente}/checklists/variables-formatos?idCheckList=${idCheckList}&idProducto=${idProducto}&numeroVersion=${numeroVersion}&idTipoFormato=${idTipoFormato}`);
  }

  public obtieneAcreditadosCoordenadas(idCliente: number, idImportacion: number): Observable<any> {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/importaciones/${idImportacion}/acreditados-coordenadas`).pipe(map((res) => <any> res.data));
  }

  public actualizaCoordenadas(idCliente: number, cuentas: any): Observable<any> {

    let data: any = {
      idCliente: idCliente,
      cuentas: cuentas
    };

    return this.serviciosVisitas.patch(`/clientes/${idCliente}/cuentas-geocalizadas`, data);
  }

  public obtienerDocumentosDescargados(idCliente: number, idFormato: number): Observable<any> {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/verifica-formatos?idFormato=${idFormato}`);
  }

  public subirFormatoVisita(uploadFile: File[], idCliente: number, idFinanciera: number, idChecklist: any) {
    const formData = new FormData();
    formData.append('uploadFile', uploadFile[0]);
    formData.append('idCliente', idCliente.toString());
    formData.append('idFinanciera', idFinanciera.toString());

    return this.storageKobraSercice.uploadFile(`/clientes/${idCliente}/checklists/${idChecklist}/formatos`, formData);
  }

  public eliminarArchivoS3(ruta: string) {
    return this.storageKobraSercice.delete(`/archivos-s3?ruta=${ruta}`);
  }

  descargarFormatoCuentas(pParams: string, idCliente: number, idFormato: number){
    return this.serviciosVisitas.getFile(`/clientes/${idCliente}/formatos-cuentas/${idFormato}${pParams}`)
  }

  descargarListadoCuentas(pParams: string, idAgente: number){
    return this.serviciosVisitas.getFile(`/excel-cuentas-desasignados/agentes/${idAgente}${pParams}`)
  }

  descargarArchivoStorage(pParams: string, idCliente: number){
    return this.storageKobraSercice.getFile(`/clientes/${idCliente}/descargar-archivos${pParams}`)
  }

  descargarContratoDetalleVisita(pParams: string, idCliente: number, idVisita : number){
    return this.storageKobraSercice.getFile(`/clientes/${idCliente}/visitas/${idVisita}/contrato${pParams}`)
  }

  descargarInfoRecopilada(pParams: string){
    return this.serviciosVisitas.getFile(`/visitas-informacion-recopilada-telefonos-grid-download${pParams}`)
  }

  descargarRevisitaListado(pParams: string){
    return this.serviciosVisitas.getFile(`/visitas-solicitudes-revisita-grid-download${pParams}`)
  }

  descargarFormatoVisita(pParams: string,  idCliente: number, idFormato: number){
    return this.serviciosVisitas.getFile(`/clientes/${idCliente}/formatos-visitas/${idFormato}${pParams}`)
  }


  public subirFormatoCuenta(uploadFile: File[], idCliente: number, idFinanciera: number, idProducto: number) {
    const formData = new FormData();
    formData.append('uploadFile', uploadFile[0]);
    formData.append('idCliente', idCliente.toString());
    formData.append('idFinanciera', idFinanciera.toString());
    return this.storageKobraSercice.uploadFile(`/clientes/${idCliente}/productos/${idProducto}/formatos`, formData);
  }

  public eliminarArchivo(idCliente: number, ruta: string, nombreArchivo: string) {
    return this.storageKobraSercice.delete(`/clientes/${idCliente}/eliminar-archivos?ruta=${ruta}&nombreArchivo=${nombreArchivo}`);
  }

  public eliminarFormatoVisita(idCliente: number, idFormato:number, idCheckList: number, numeroVersion: number, idProducto: number) {
    let data: any = {
      idFormato: idFormato,
      idCheckList: idCheckList,
      numeroVersion: numeroVersion,
      idProducto: idProducto
    };
    return this.serviciosVisitas.patch(`/clientes/${idCliente}/checklists/${idCheckList}/formatos`, data);
  }

  public eliminarVarible(idCliente: number, idFormatoVariable:number, idProducto: number, idChecklist: number,numeroVersion: number ) {
    return this.serviciosVisitas.delete(`/clientes/${idCliente}/formatos-variables/${idFormatoVariable}?idProducto=${idProducto}&numVersion=${numeroVersion}&idChecklist=${idChecklist}`);
  }

  public guardarFormatoVisita(archivo: any, idCliente: number, idProducto: number, idCheckList: number, numeroVersion: number, descripcion: string, idTipoFormato:number, formatoConError:any ) {
    let data: any = {
      archivo: archivo,
      idCheckList: idCheckList,
      numeroVersion: numeroVersion,
      idProducto : idProducto,
      descripcion: descripcion,
      idTipoFormato: idTipoFormato,
      formatoConError: formatoConError
    };

    return this.serviciosVisitas.post(`/clientes/${idCliente}/checklists/${idCheckList}/formatos`, data);
  }

  public guardarFormatoCuenta(archivo: any, idCliente: number, idProducto: number, descripcion: string) {
    let data: any = {
      archivo: archivo,
      idProducto : idProducto,
      descripcion: descripcion
    };

    return this.serviciosVisitas.post(`/clientes/${idCliente}/productos/${idProducto}/formatos`, data);
  }

  public obtenerArchivos(idCliente: number, idCheckList: number, numeroVersion: number, tipoFormato: number) {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/checklists/formatos?idCheckList=${idCheckList}&numeroVersion=${numeroVersion}&idTipoFormato=${tipoFormato}`);
  }

  public obtenerFormatosGrid(idCliente: number, idCheckList: number, paramatros: string) {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/checklist/${idCheckList}/formatos-grid` + paramatros);
  }

  public obtenerVariablesDinamicas(idCliente: number, idCheckList: number, numeroVersion: number) {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/formatos-variables?idCheckList=${idCheckList}&numeroVersion=${numeroVersion}`);
  }

  public obtenerCondiciones(idCliente: number, idFormatoVariable: number) {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/condiciones?idFormatoVariable=${idFormatoVariable}`);
  }

  public obtenerCliente(idCliente: number) {
    return this.serviciosVisitas.get(`/clientes?idCliente=${idCliente}`);
  }

  public obtenerClienteFinanciera(idFinanciera: number) {

    return this.serviciosVisitas.get(`/clientes?idExternoKobra=${idFinanciera}`).pipe(
      map(res => <Financiera> res.data[0])
    )

  }

  public obtenerFormatosDescargados(idCliente: number, idCheckList: number, numeroVersion: number, idVisita: number, idAgente: number) {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/checklists/formatos-descargados?idCheckList=${idCheckList}&numeroVersion=${numeroVersion}&idVisita=${idVisita}&idAgente=${idAgente}`);
  }

  public obtieneDatosCheckList(idCliente: number, idProducto: number) {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/checklists?idProducto=${idProducto}`);
  }

  public descargarArchivo(idCliente: number, idVisita: number, idAgente:number, idFormato) {
    return this.storageKobraSercice.get(`/clientes/${idCliente}/visitas/${idVisita}/formato-visita/${idFormato}?idAgente=${idAgente}`);
  }

  public clientAddress(queryString: string): Observable<ClientAddress> {
    let uri = `/acreditados/domicilios${queryString}`;
    return this.serviciosVisitas.get(uri).pipe(
      map(res => {
        let clientAddress: ClientAddress = ClientAddress.map(res.data[0]);
        return clientAddress;
      })
    );
  }

  public guardarVariable(idCliente:number, data: any): Observable<any> {
    return this.serviciosVisitas.post(`/clientes/${idCliente}/formatos-variables`, data);
  }

  public actualizarVariable(idCliente:number, idFormatoVariable: number, data: any): Observable<any> {
    return this.serviciosVisitas.patch(`/clientes/${idCliente}/formatos-variables/${idFormatoVariable}`, data);
  }

  public obtenerBorrador(idCliente: number, idAgente: number, acreditado: any) {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/agentes/${idAgente}/borrador-visita?idAcreditado=${acreditado.idAcreditado}&idCuenta=${acreditado.idCuenta}&idAcreditadoCuentaDomicilio=${acreditado.idAcreditadoCuentaDomicilio}`);
  }

  public eliminarFotoVisita(idCliente: number, idVisita: number, idAgente: number, idVisitaFoto: number) {
    return this.storageKobraSercice.delete(`/clientes/${idCliente}/visitas/${idVisita}/foto?idAgente=${idAgente}&idVisitaFoto=${idVisitaFoto}`);
  }

  public eliminarFotoCheckLists(nombreFoto: string, idAgente: number) {
    return this.storageKobraSercice.delete(`/checklists/foto?idAgente=${idAgente}&nombre=${nombreFoto}`);
  }

  public eliminarContratoVisita(idCliente: number, idAcreditado: number, idAcreditadoArchivo: number, Params: string) {
    return this.storageKobraSercice.delete(`/clientes/${idCliente}/acreditados/${idAcreditado}/archivos/${idAcreditadoArchivo}?${Params}`);
  }

  public obternerFotosVisita(visitID: number, idAgent: number, idCliente: number, idFinanciera: number): Observable<any> {
    return this.serviciosVisitas.get(`${this.resource}/${visitID}/fotos?idAgent=`+idAgent+'&idCliente='+idCliente+'&idFinanciera='+idFinanciera);
  }

  public checklistInfo(idCliente, idProducto, idAcreditado, idAcreditadoPG, idCuentaPG) {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/productos/${idProducto}/checklist-info/acreditado/${idAcreditado}?idAcreditadoPG=${idAcreditadoPG}&idCuentaPG=${idCuentaPG}`);
  }

  obtenerHistorialCuenta(idCliente: number, idExterno: string, idCuenta: number) {
    return this.serviciosVisitas.get(`/clientes/${idCliente}/cuentas/historial-cuenta?idExterno=${idExterno}&idCuenta=${idCuenta}`);
  }

  public saveDomicilioAgente(agenteId: number, domicilio: object): Observable<any> {
    return this.patch(`/agentes/${agenteId}/domicilio`, domicilio)
      .pipe(
        map((res) => {
          return res.data as any;
        })
      );
  }

}
