import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PreCobradorMovimiento, PreCobradorMovimientoPagination } from './ajuste-movimiento-agentes.interface';
import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { map, catchError } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PreAgentesMovimientosService {
  public movimientos$: Observable<PreCobradorMovimiento[]>;
  public movimiento: PreCobradorMovimiento;
  public loader: boolean;

  public pagination: PreCobradorMovimientoPagination;
  public pageSize = 20;
  public fromRowPage: number;
  public toRowPage: number;

  public movStatus = [
    { id: 1, nombre: 'Pendiente autorizar' },
    { id: 2, nombre: 'Autorizado' },
    { id: 3, nombre: 'Cancelado' },
  ];

  

  public filtros = {
    idCobradorFacturacionPeriodo:  null,
    nombrePeriodo:  null,
    idCobrador:  null,
    nombreCobrador:  null,
    idConcepto:  null,
    nombreConcepto:  null,
    movStat:  null,
    fechaInicio:  null,
    fechaFin:  null,
    page: 1
  }
  

  constructor(
    private saldosKobraServ: ServiciosSaldosKobraService,
    private app: ApplicationService,
    private fb: FormBuilder
  ) {

  }

  obtenerMovimientos(queryParams: string): Observable<any> {
    return this.saldosKobraServ.get(`/pre-cobradores-movs${queryParams}`);   
  }
  

  agregarMovimiento(movimiento: PreCobradorMovimiento): Observable<any> {
    return this.saldosKobraServ.post('/pre-cobradores-movs', movimiento);
  }

  autorizarMovimiento(movimientos: PreCobradorMovimiento[]): Observable<any> {
    return this.saldosKobraServ.put(`/autorizar-pre-movimientos`, movimientos);
  }
  
  cancelarMovimiento(movimientos: PreCobradorMovimiento[]): Observable<any> {
    return this.saldosKobraServ.put(`/cancelar-pre-movimientos`, movimientos);
  }
  
  
  descargarMovimientosAgentesExcel(queryParams: string) {
    return this.saldosKobraServ.get(`/pre-cobradores-movs-excel${queryParams}`);
  }


  calcFromToRowPage() {
    if (this.pagination.pageData.length <= 0) {
      this.fromRowPage = 0;
      this.toRowPage = 0;
    } else {
      this.fromRowPage = (this.pageSize * this.pagination.page) - (this.pageSize - 1);

      if (this.pagination.page === this.pagination.pages) {
        this.toRowPage = this.pagination.totalRows;
      } else {
        this.toRowPage = this.pagination.page * this.pageSize;
      }
    }
  }


}
