import { ProductosService } from './../productos.service';
import { Lender } from '@lender/lender.model';
import { ProductoNvo, TipoImportacion } from '../productos-nvo.model';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { take } from 'rxjs/operators';
import { LenderService } from "../../../lender/lender.service";
import { ApplicationService } from 'src/app/application/shared/application.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ActivatedRoute, Params, UrlSegment, Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'productos-editar',
  templateUrl: './productos-editar.component.html',
  styleUrls: ['./productos-editar.component.css'],
  providers: [LenderService]
})
export class ProductosNvoEditarComponent implements OnInit {
  sessionData: SessionData;
  producto: ProductoNvo;
  listaProductos: ProductoNvo[] = [];
  tiposImportacion: TipoImportacion[] = [
    {idTipoImportacion: 2, nombre: 'Actualización de saldos'},
    //{idTipoImportacion: 3, nombre: 'Actualización de información personal'}
  ];
  tiposProductos: any[] = [];
  cuentasNuevas: TipoImportacion = {idTipoImportacion: 1, nombre: 'Cuentas nuevas'};
  tiposImportacionSeleccionados: TipoImportacion[]= []; 
  pagoAlGestor: number | null = null;
  financieras: Lender[] = [];
  idFinanciera: number = 0;
  idCliente: number = 0;
  titulo: string = 'Nuevo';
  private nombre: string = '';

  loadings: any = {
    financieras: true,
    productos: true,
    guardando: false,
    tiposImportacion: true,
    tiposProductos: true
  }

  requireds: any = {
    financiera: false,
    nombre: false
  }
  editarPresente: boolean = false;
  idProductoParams:number;
  nombreProducto:string;
  nombreCliente: string;
  idClienteParams: number;
  idFinancieraParams: number;
  habilitarAgregar: boolean = false;
  bloquear:boolean = false;

  constructor(
    private lenderService: LenderService,
    private productosService: ProductosService,
    private localStorageService: LocalStorageService,
    private app: ApplicationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.sessionData = this.localStorageService.getSessionData();
  }

  ngOnInit() {
    this.producto = new ProductoNvo;
    this.route.url.subscribe(urlSegments => {
      const urlSegmentStrings = urlSegments.map(segment => segment.path);
      this.editarPresente = urlSegmentStrings.includes('editar');
    });
    this.tiposImportacionSeleccionados.push(this.cuentasNuevas);
    this.idClienteParams = +this.route.snapshot.queryParamMap.get('idCliente');
    this.idFinancieraParams = +this.route.snapshot.queryParamMap.get('idFinanciera');

    if (this.editarPresente) {
      this.titulo = 'Editar';
      this.habilitarAgregar = true;
      this.idCliente = this.idClienteParams;
      this.idFinanciera = this.idFinancieraParams;
      this.idProductoParams = +this.route.snapshot.queryParamMap.get('idProducto');
      this.nombreProducto = this.route.snapshot.queryParamMap.get('nombreProducto');
      this.nombreCliente = this.route.snapshot.queryParamMap.get('nombreCliente');
      if (!this.idCliente || !this.idFinanciera || !this.idProductoParams || !this.nombreProducto || !this.nombreCliente) {
        this.app.showSnackbar('¡Aviso!', 'Faltan parámetros en la URL.', 2000, 'error');
        this.router.navigate([`catalogos/productos`]);
      }else{
        /*this.producto.idCliente = this.idCliente;
        this.producto.idFinanciera = this.idFinanciera;
        this.producto.idProducto = this.idProductoParams;*/
        this.obtenerProductosNoVincunladosAlCliente(this.idCliente);
        this.loadProducto();
      }
    } else {
      //this.eliminarParametros();
      if(this.idFinancieraParams && this.idClienteParams) {
        this.idCliente = this.idClienteParams;
        this.idFinanciera = this.idFinancieraParams;
      }else {
        this.idCliente = this.sessionData.idCliente;
        this.idFinanciera = this.sessionData.idFinanciera;
      }
      this.obtenerProductosNoVincunladosAlCliente(this.idCliente);
      this.producto = {
        idProducto: 0,
        idTipoProducto: 0,
        nombre: '',
        editable: true,
        descripcion: '',
        tiposImportacion: [
          {idTipoImportacion: 1, nombre: 'Cuentas nuevas'}
        ],
        nombreCliente: '',
        nombreTipoProducto: '',
        idFinanciera: this.idFinanciera,
        idCliente: this.idCliente,
        administradores :[]
      }
      this.loadTiposImportacion();
    }
    //this.idFinanciera = this.sessionData.idFinanciera;
    this.loadLenders();
    this.loadTiposProductos();
  }

  onChangeProducto(data) {
    if(data !== null && typeof data === 'object') {
      this.producto.nombre = data.nombre;
      this.producto.idTipoProducto = data.idTipoProducto;
      this.producto.descripcion = data.descripcion;
      this.bloquear = true;
    }else {
      this.producto.descripcion = null;
      this.bloquear = false;
      this.producto.idTipoProducto = null;
    }
  }


  private loadProducto(){
    this.loadings.productos = true;

    this.productosService.obtenerProducto(this.idCliente, this.idProductoParams)
    .pipe(
        take(1)
    ).subscribe((res: any) => {
        this.loadings.productos = false;
        this.producto = ProductoNvo.map(res.data.producto);
        this.producto.tiposImportacion = TipoImportacion.mapArray(res.data.tipoImportacion);
        this.loadTiposImportacion();
    }, (err: any) => {
        this.loadings.productos = false;
        this.app.showError(err);
    });
  }

  private obtenerProductosNoVincunladosAlCliente(idCliente: number) {
    this.loadings.productos = true;
    
    this.productosService.obtenerProductosNoVincunladosAlCliente(idCliente)
      .pipe(take(1))
      .subscribe({
        next: (res: any) => {
          this.loadings.productos = false;
  
          // Usa ProductoNvo.mapArray para mapear los datos
          this.listaProductos = ProductoNvo.mapArray(res.data)
            .filter(producto => producto.idTipoProducto !== 7);

        },
        error: (err: any) => {
          this.loadings.productos = false;
          this.app.showError(err);
        },
        complete: () => {
          //console.log('Productos no vinculados cargados correctamente.');
          //console.log(this.listaProductos);
        }
      });
  }

  private eliminarParametros(){
    if(localStorage.getItem('procutoNvoEditarTemp')){
      localStorage.removeItem('procutoNvoEditarTemp');
    }
  }

  private loadLenders(): void {
    this.loadings.financieras = true;

    this.lenderService.getAll('active').subscribe(
        res => {
            this.financieras = Lender.mapArray(res);
            //this.idFinanciera = this.sessionData.idFinanciera;
            this.onChangeLender(this.idFinanciera);
            this.loadings.financieras = false;
        },
        err => {
            this.app.showError(err);
            this.loadings.financieras = false;
        },
        () => this.loadings.financieras = false
    );
  }

  public onChangeLender(event) {
    if (event) {
        this.financieras.forEach(financiera => {
            if (financiera.idFinanciera == event) {
              this.producto.idFinanciera = financiera.idFinanciera;
              this.producto.idCliente = financiera.idCliente;
              this.obtenerProductosNoVincunladosAlCliente(this.producto.idCliente);
            }
        });
    }
    else {
      this.producto.idFinanciera = null;
      this.producto.idCliente = null;
      this.idFinanciera = null;
      this.idCliente = null;
    }
  }

  private loadTiposImportacion(){
    this.loadings.tiposImportacion = true;
    this.productosService.obtenerTiposImportacion()
    .pipe(
        take(1)
    ).subscribe((res: any) => {
        this.loadings.tiposImportacion = false;
        this.tiposImportacion = res.data;
        if(this.producto.tiposImportacion.length > 1){
          for (let index = 1; index < this.producto.tiposImportacion.length; index++) {
            const element = this.producto.tiposImportacion[index];
            if(element.idTipoImportacion != 1){
              for (let j = 0; j < this.tiposImportacion.length; j++) {
                const tipoImportacion = this.tiposImportacion[j];
                if(element.idTipoImportacion == tipoImportacion.idTipoImportacion){
                  this.tiposImportacionSeleccionados.push(tipoImportacion);
                  j = this.tiposImportacion.length;
                }
              }
            }
          }
        }
    }, (err: any) => {
        this.loadings.tiposImportacion = false;
        this.app.showError(err);
    });
  }

  private loadTiposProductos() {
    this.loadings.tiposProductos = true;
    this.productosService.obtenerTiposProductos()
    .pipe(take(1))
    .subscribe((res: any) => {
        this.loadings.tiposProductos = false;

        // Filtramos para excluir id_tipo_producto = 7 Cobranza Premium
        this.tiposProductos = res.data.filter((tipo: any) => tipo.idTipoProducto !== 7);

    }, (err: any) => {
        this.loadings.tiposProductos = false;
        this.app.showError(err);
    });
}


  cambioSeleccion(){
    this.producto.tiposImportacion = this.tiposImportacionSeleccionados;

    /*for (let index = 0; index < this.tiposImportacionSeleccionados.length; index++) {
      const element = this.tiposImportacionSeleccionados[index];
      this.producto.tiposImportacion.push(element);
    }*/
  }

  cancel() {
    this.eliminarParametros();
    this.router.navigate([`catalogos/productos`], {
      //queryParamsHandling: 'preserve'
    });
  }

  save() {
    if(!this.producto.idCliente){
      this.app.showSnackbar('¡Aviso!', 'Es necesario seleccionar un cliente.', 2000, 'warning');
      return;
    }

    if(!this.producto.nombre){
      this.app.showSnackbar('¡Aviso!', 'Es necesario capturar el nombre del producto.', 2000, 'warning');
      return;
    }
    if(!this.producto.descripcion){
      this.app.showSnackbar('¡Aviso!', 'Es necesario asignarle una descripción al producto.', 2000, 'warning');
      return;
    }
    
    if(!this.producto.idTipoProducto){
      this.app.showSnackbar('¡Aviso!', 'Es necesario seleccionar el tipo del producto.', 2000, 'warning');
      return;
    }

    let productoEnviar: any = {
      idProducto: this.producto.idProducto,
      idTipoProducto: this.producto.idTipoProducto,
      nombreProducto: this.producto.nombre,
      descripcion: this.producto.descripcion,
      tiposImportacion: this.producto.tiposImportacion
    };

    this.loadings.guardando = true;
    let loading: number = this.app.showLoading('Guardando producto...');
    let suscriber = this.titulo == 'Nuevo' ?  this.productosService.agregarProducto(this.producto.idCliente, productoEnviar) : this.productosService.editarProducto(this.producto.idCliente, productoEnviar);
    suscriber
    .pipe(
        take(1)
    ).subscribe((res: any) => {
        this.loadings.guardando = false;
        //this.eliminarParametros();
        this.app.hideLoading(loading);
        this.app.showSnackbar('¡Aviso!', 'Se ha guardado el producto correctamente.', 1000, 'success');
        this.router.navigate([`catalogos/productos`], {
          //queryParamsHandling: 'preserve'
        });
    }, (err: any) => {
        this.loadings.guardando = false;
        this.app.hideLoading(loading);
        this.app.showError(err);
    });
  }
}
