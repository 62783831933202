<mat-card class="card kobra-card">
  <mat-card-header>
    <mat-card-title>Bitácora de movimientos</mat-card-title>
</mat-card-header>
  <mat-card-content>
    <div class="timeline">
      <div class="button-container-wrapper button-container" *ngIf="!modoAgregar">
        <div class="button-toggle-group-container  right" >
            <!--  -->
            <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" class="right-align">
                <mat-button-toggle value="addtimeline"
                    matTooltip="Agregar"
                    mat-tooltip-panel-above
                    (click)="modoAgregar = true;">
                    <mat-icon class="kobra-icon">add</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
      </div>
      <div class="timeline-item comment-form">
        <!--div class="timeline-line"></div>
        <div class="timeline-marker"></div-->
        <div class="comment-form" *ngIf="modoAgregar">
          <div class="kobra two columns" style="width: 100%;">
            <div class="kobra column two-colspan">
              <mat-form-field appearance="fill" style="min-width: 20px; width: calc(100% - 100px);">
                <mat-label style="font-weight: normal;">Comentario</mat-label>
                <textarea matInput textSanitizer  placeholder="Añade un nuevo comentario a la bitácora" [(ngModel)]="nuevoComentario" required></textarea>
                <!--input matInput textSanitizer  maxlength="1000" multil placeholder="Añade un nuevo comentario a la bitácora" [(ngModel)]="nuevoComentario" name="comment" required-->
                <mat-icon matSuffix>add_comment</mat-icon>
              </mat-form-field>
              <div class="button-container-wrapper button-container button-agregar">
                <div class="button-toggle-group-container">
                    <!--  -->
                    <button mat-stroked-button class="kobra-button" type="submit" (click)="addComment()"
                      matTooltip="guardar"
                      mat-tooltip-panel-above
                    >
                      <mat-icon>save</mat-icon>
                    </button>
                    <!--mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" class="right-align">
                        <mat-button-toggle value="addtimeline"
                            matTooltip="Agregar"
                            mat-tooltip-panel-above
                            class="boton-guardar"
                            (click)="addComment()">
                            <mat-icon>save</mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group-->
                </div>
              </div>
            </div>
            <div class="kobra column">

              <!--button mat-stroked-button style="background-color: #21ba45; color:white; margin-left: 10px; margin-top: 15px; height: 36px;" (click)="addComment()"><mat-icon>add</mat-icon>Añadir</button-->
            </div>
          </div>
        </div>
      </div>
      <div class="contenidoConScroll" style="height: {{height}}; max-height: {{height}};" (scroll)="onScroll($event)">
        <div *ngIf="bitacoras.length === 0" style="margin-top: 30px; margin-left: 40px;">
          No hay datos para mostrar.
        </div>
        <div [@timelineAnimation]>

        </div>
        <div *ngFor="let event of bitacoras; index as e" class="timeline-item">
          <div class="timeline-line" *ngIf="e+1 < bitacoras.length"></div>
          <div class="timeline-marker"></div>

          <div class="timeline-text">
            <div class="timeline-title-container">
              <ng-container *ngIf="event.tipoUsuario?.trim().toLowerCase() !== 'campania'">
                <span class="timeline-title">
                  {{event.tipoUsuario}} <b>{{event.usuario}}</b> {{event.accion.trim()}}
                  <span
                    *ngIf="event.accionMarcada == 'Activa' || event.accionMarcada == 'Visitada'
                    || event.accionMarcada == 'Apartada'  || event.accionMarcada == 'En Espera de Confirmación'  || event.accionMarcada == 'Inactiva'"
                    [ngClass]="{'cuentaActiva': event.accionMarcada === 'Activa'
                    , 'cuentaEnEspera' : event.accionMarcada === 'Visitada'
                    , 'cuentaApartada' : event.accionMarcada === 'Apartada'
                    , 'cuentaEnEsperaDeConfirmacion' : event.accionMarcada === 'En Espera de Confirmación'
                    , 'cuentaInactiva' : event.accionMarcada === 'Inactiva'}"> {{event.accionMarcada}}
                  </span>
                  <b
                    *ngIf="event.accionMarcada != 'Activa' && event.accionMarcada != 'Visitada'
                    && event.accionMarcada != 'Apartada'  && event.accionMarcada != 'En Espera de Confirmación'  && event.accionMarcada != 'Inactiva'
                    && event.flagVisita === false"
                  > {{event.accionMarcada}}</b>
                  <b *ngIf="event.flagVisita">
                    <a routerLink="/visits/visit/{{event.accionMarcada}}"> {{event.accionMarcada}} </a>
                  </b>
                  <ng-container *ngIf="event.folioDomicilio || event.flagVisita">{{event.folioDomicilio ? (event.flagVisita ? ' al folio domicilio' : ' con el domicilio') : ''}} <b>{{event.folioDomicilio}}</b></ng-container>.
                </span>
              </ng-container>

              <ng-container *ngIf="event.tipoUsuario?.trim().toLowerCase() === 'campania'">
                <div class="timeline-title campaign-title">
                  <b>{{ event.usuario }} - </b>

                  <!-- Canal entre 4 y 9 -->
                  <ng-container *ngIf="+event.accion >= 4 && +event.accion <= 9">
                    <mat-icon class="mat-icon tipo-icono tipo-{{event.accion}}">
                      {{ tipoCampaniaMap[event.accion]?.icono }}
                    </mat-icon>

                    <span>
                      {{ getMensajeCampania(event) }} para la campaña <b>{{ event.accionMarcada }}</b>
                    </span>
                  </ng-container>

                  <!-- Otros tipos de acción -->
                  <ng-container *ngIf="event.accion == '1' || event.accion == '3'">
                    <span>
                      La campaña <b>{{ event.accionMarcada }}</b> se ha
                      <b *ngIf="event.accion == '1'" class="campania-green"> activado</b>
                      <b *ngIf="event.accion == '3'" class="campania-red"> inactivado</b>
                    </span>
                  </ng-container>

                  <ng-container *ngIf="event.accion == '2'">
                    <span>Respuesta de la campaña <b>{{ event.accionMarcada }}:</b></span>
                  </ng-container>
                </div>
              </ng-container>


            </div>
            <div class="timeline-date-content">
              <span class="timeline-date">{{event.fecha | date:'dd/MM/yyyy HH:mm'}}</span>
            </div>
            <div *ngIf="event.comentario && event.accion != '2'" class="timeline-content">
              <p style="word-break: break-all;">{{event.comentario}}</p>
            </div>
            <div *ngIf="event.respuesta && event.accion == '2'" class="timeline-content">
              <span *ngFor="let respuesta of event.respuesta; index as e">
                <b>{{respuesta.clave}}:</b> {{respuesta.valor}}
                <br>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </mat-card-content>
  <mat-card-footer *ngIf="loadingAccountHistory">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </mat-card-footer>
</mat-card>

