import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { ApplicationService } from 'src/app/application/shared/application.service';
import { ServiciosKobraService } from '@servicios/servicios-kobra.service';
import { VisitService } from 'src/app/visit/visit.service';
import { NgxStarsComponent } from 'ngx-stars';
import { ModalVisitaDetalleComponent } from 'src/app/visit/detalle-dialog/visit-detail-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';

declare const $: any;

@Component({
  selector: 'calificacion-visitas-estrellas',
  templateUrl: './calificacion-visitas-estrellas.component.html',
  styleUrls: ['./calificacion-visitas-estrellas.component.css'],
  providers: [VisitService]
})
export class CalificacionVisitasEstrellasComponent{
  @Input() calificacion: number;
  @Input() idAgent: number;
  @Input() idFinanciera: number;
  @Input() idVisita: number;
  @Input() visitaIndex: number;
  @Input() disabled: boolean;
  @Input() isSuperAdmin: boolean;
  @Input() etiquetasCuenta: any;
  @Input() visitasTotales:any;

  @ViewChild(NgxStarsComponent) starsComponent: NgxStarsComponent;
  @Output() siguienteVisita: EventEmitter<any> = new EventEmitter<any>();


  public form: FormGroup;
  public comentarios: Observable<any>;
  public estrellas: number;
  private idAgente: number;

  constructor(
    private formBuilder: FormBuilder,
    private app: ApplicationService,
    private visitService: VisitService,
    public dialogRef: MatDialogRef<ModalVisitaDetalleComponent>,
  ) {
    this.form = this.formBuilder.group({
      idVisita: [''],
      idAgent: [''],
      idFinanciera: [''],
      idSuperAdministrador: [''],
      calificacion: ['', Validators.required],
      comentLibre: ['', Validators.required],
      idEtiquetaCuenta: [null],
      comentario: ['']
    });
  }

  ngOnInit() {


    this.form.get('idVisita').patchValue(this.idVisita);
    this.form.get('idAgent').patchValue(this.idAgente);
    this.form.get('idFinanciera').patchValue(this.idFinanciera);
    this.form.get('idSuperAdministrador').patchValue(localStorage.getItem('userID'));

  }
  ngAfterViewInit() {
    this.crearRating();
  }
  
  asignarCalificacion(event: any){
    this.estrellas = event;
    this.form.get('calificacion').patchValue(this.estrellas);
  }

  private crearRating(): void {
    if (this.starsComponent) {
      this.starsComponent.setRating(this.calificacion);
      this.starsComponent.readonly = this.disabled;
    }
  }

  public calificar(): void {
    const loading = this.app.showLoading('Guardando calificación...');
    this.form.value.idAgente = this.form.value.idAgent;
  
    const callBack = (res: any) => {
      if (res === true) {
        // El resultado es true
        // Realiza las acciones relacionadas con el caso de éxito
        this.form.reset();
        this.form.get('comentLibre').patchValue('');

        this.app.showSnackbar('¡Aviso!', 'Se ha calificado correctamente las visitas', 3000, 'success');
        this.dialogRef.close(true);
        
      } else {
        // El resultado es false o cualquier otro valor
        // Realiza las acciones relacionadas con el caso de error
        this.app.showError('Error en la calificación');
      }
  
      this.app.hideLoading(loading); // Oculta el loading en ambos casos
    };
  
    const errorBack = (error: any) => {
      this.app.hideLoading(loading); // Asegúrate de ocultar el loading en caso de error
      this.app.showError(error);
    };
  
    this.visitService.post(`/calificaciones`, this.visitasTotales)
      .pipe(
        take(1),
      ).subscribe(callBack, errorBack);
  }
  
  

  onCalificarVisita() {

   
    const datosR = {
      calificacion: this.form.get('calificacion').value,
      comentLibre: this.form.get('comentLibre').value,
      comentario: this.form.get('comentLibre').value,
      idEtiquetaCuenta: this.form.get('idEtiquetaCuenta').value,
    };

    for (const visita of this.visitasTotales) {
      if (visita.calificacion === null) {
        visita.calificacion = datosR.calificacion;
      }
      if (visita.comentLibre === null) {
        visita.comentLibre = datosR.comentLibre;
      }
      if (visita.comentario === null) {
        visita.comentario = datosR.comentario;
      }
      if (visita.idEtiquetaCuenta === null) {
        visita.idEtiquetaCuenta = datosR.idEtiquetaCuenta;
      }

      
      
    }
    
    this.calificar();
  }

  
 

  validaSubmit() {
    return this.form.get('calificacion').valid && (this.form.get('comentLibre').valid && this.form.get('comentLibre').value.trim());
  }
}
