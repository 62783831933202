import { Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { LenderService } from "../../../lender/lender.service";
import { map, startWith, take } from 'rxjs/operators';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { PaquetesFinancieraDetalleService } from './paquetes-financiera-detalle.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatExpansionPanel } from '@angular/material/expansion';


@Component({
  selector: 'app-paquetes-financiera-detalle',
  templateUrl: './paquetes-financiera-detalle.component.html',
  styleUrls: ['./paquetes-financiera-detalle.component.css'],
  providers: [
    LenderService
  ]
})
export class PaquetesFinancieraDetalleComponent implements OnInit {

  @ViewChild('searchInput', { read: MatAutocompleteTrigger })
  autocompleteTrigger!: MatAutocompleteTrigger;

  @ViewChildren(MatExpansionPanel)
  expansionPanels!: QueryList<MatExpansionPanel>;
  
  conceptoActual: any = null;
  public title: string;
  public idFinanciera: number;
  public idFinancieraPaquete: number;
  public financieraName: string;
  public detalleForm: FormGroup;
  public conceptosCampania: any[] = [];
  public conceptosProducto: any[] = [];
  public conceptosEtiquetaVisita: any[] = [];
  public form: FormGroup;
  public items: FormArray;
  public loaderText: string | null;
  public conceptosLoader: boolean = false;
  public saveButton: boolean = false;
  private financieraPaqueteConceptos: any[] = [];
  private conceptosCompletos: any[] = [];
  private itemsBorrados: any[] = [];
  searchControl = new FormControl();
  filteredConceptosProducto = [];
  filteredConceptosEtiquetaVisita = [];
  filteredConceptosCampania = [];
  public conceptoSeleccionado: boolean = false;
  conceptos: any[] = [];

  constructor(
    private acRoute: ActivatedRoute,
    private lenderService: LenderService,
    private app: ApplicationService,
    private paquetesFinancieraDetalleService: PaquetesFinancieraDetalleService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.init();
    this.searchControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    )
    .subscribe();
  }

  private _filter(value: any) {
    const filterValue = typeof value === 'string' ? this._normalize(value) : '';
  
    this.filteredConceptosProducto = this.conceptosProducto.filter(concepto =>
      this._normalize(concepto.nombreConcepto).includes(filterValue)
    );
  
    this.filteredConceptosEtiquetaVisita = this.conceptosEtiquetaVisita.filter(concepto =>
      this._normalize(concepto.nombreConcepto).includes(filterValue)
    );
  
    this.filteredConceptosCampania = this.conceptosCampania.filter(concepto =>
      this._normalize(concepto.nombreConcepto).includes(filterValue)
    );
  }
  

  private _normalize(text: string): string {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  }

  private init(): void {
    this.title = 'Editar precios por tipo de visitas';
    this.detalleForm = this.formBuilder.group({
      items: this.formBuilder.array([])
    });
    this.idFinanciera = +this.acRoute.parent?.snapshot.params['idFinanciera'];
    this.idFinancieraPaquete = this.acRoute.snapshot.params['idFinancieraPaquete'];
    if (!this.financieraName) {
      this.cargarFinanciera(this.idFinanciera);
    }

    this.paquetesFinancieraDetalleService.getConceptos(this.idFinanciera, this.idFinancieraPaquete)
      .pipe(take(1))
      .subscribe((res) => {
        this.items = this.detalleForm.get('items') as FormArray;
        this.financieraPaqueteConceptos = res;
        this.getConceptosFinanciera();
        for( let i = 0; i < res.length; i++ ) {
          this.items.push(this.createItem( res[i].idConcepto, res[i].nombreConcepto, res[i].precio ));
        }
      }, (err) => {
        this.app.showError(err);
      });
  }

  limpiarBusqueda() {
    this.searchControl.setValue('');
  }

  getConceptosFinanciera() {
    this.loaderText = 'Obteniendo conceptos...';
    this.conceptosLoader = true;
    this.paquetesFinancieraDetalleService.getConceptosEtiquetasProductos(this.idFinanciera)
      .pipe(take(1))
      .subscribe((res) => {
        this.loaderText = null;
        this.conceptosCompletos = res;

        let conceptosTemp = this.conceptosCompletos.filter(concepto => {
          return !this.financieraPaqueteConceptos.some(item => item.idConcepto === concepto.idConcepto);
        });

        // Agrupar conceptos por tipo
        this.conceptosProducto = conceptosTemp.filter(concepto => concepto.tipoTarifa === 'producto');
        this.conceptosEtiquetaVisita = conceptosTemp.filter(concepto => concepto.tipoTarifa === 'etiquetaVisita');
        this.conceptosCampania = conceptosTemp.filter(concepto => concepto.tipoTarifa === 'campania');
        
        this.filteredConceptosProducto = this.conceptosProducto;
        this.filteredConceptosEtiquetaVisita = this.conceptosEtiquetaVisita;
        this.filteredConceptosCampania = this.conceptosCampania;
        
        this.conceptosLoader = false;
      }, (err) => {
        this.loaderText = null;
        this.app.showError(err);
        this.conceptosLoader = false;
      });
  }
  
  onConceptoSelected(event: any) {
    this.conceptoActual = event.option.value;
    this.agregarConcepto();

    // En el siguiente tick, colapsar los panels
    setTimeout(() => {
      this.expansionPanels.forEach(panel => panel.close());
      // Forzar que el overlay se reposicione
      this.autocompleteTrigger.updatePosition();
    }, 0);
  }

  agregarConcepto() {
    if (!this.conceptoActual) return;
  
    const precioInicial = this.conceptoActual.precioBase ?? 0;
  
    this.items.push(this.createItem(
      this.conceptoActual.idConcepto,
      this.conceptoActual.nombreConcepto,
      precioInicial,
      true
    ));
  
    const tipoTarifa = this.conceptosCompletos.find(concepto => concepto.idConcepto === this.conceptoActual.idConcepto)?.tipoTarifa;
  
    if (tipoTarifa === 'producto') {
      this.conceptosProducto = this.conceptosProducto.filter(concepto => concepto.idConcepto !== this.conceptoActual.idConcepto);
      this.filteredConceptosProducto = [...this.conceptosProducto];
    } else if (tipoTarifa === 'etiquetaVisita') {
      this.conceptosEtiquetaVisita = this.conceptosEtiquetaVisita.filter(concepto => concepto.idConcepto !== this.conceptoActual.idConcepto);
      this.filteredConceptosEtiquetaVisita = [...this.conceptosEtiquetaVisita];
    } else if (tipoTarifa === 'campania') {
      this.conceptosCampania = this.conceptosCampania.filter(concepto => concepto.idConcepto !== this.conceptoActual.idConcepto);
      this.filteredConceptosCampania = [...this.conceptosCampania];
    }
  
    this.conceptoActual = null;
    this.searchControl.setValue('');
  }
  
  
  borrarConcepto(item: any, index: number) {
    const idConcepto = item.controls['idConcepto'].value;
    // Extraer el precio original del control
    const precioOriginal = item.controls['precioOriginal'].value;
  
    let conceptoBorrado = {
      idConcepto: idConcepto,
      nombreConcepto: item.controls['nombreConcepto'].value,
      precioBase: precioOriginal, // Se conserva el precio original con el que vino la BD
      nuevo: item.controls['nuevo'].value
    };
  
    // Para determinar el tipo de tarifa, se intenta buscar en conceptosCompletos
    const conceptoOriginal = this.conceptosCompletos.find(concepto => concepto.idConcepto === idConcepto);
    const tipoTarifa = conceptoOriginal ? conceptoOriginal.tipoTarifa : null;
  
    this.itemsBorrados.push(conceptoBorrado);
    this.items.removeAt(index);
  
    if (tipoTarifa === 'producto') {
      this.conceptosProducto.push(conceptoBorrado);
      this.filteredConceptosProducto = [...this.conceptosProducto];
    } else if (tipoTarifa === 'etiquetaVisita') {
      this.conceptosEtiquetaVisita.push(conceptoBorrado);
      this.filteredConceptosEtiquetaVisita = [...this.conceptosEtiquetaVisita];
    } else if (tipoTarifa === 'campania') {  // Flujo para regresar campañas
      this.conceptosCampania.push(conceptoBorrado);
      this.filteredConceptosCampania = [...this.conceptosCampania];
    }
  }
  
  createItem(idConcepto: number, nombreConcepto: string, precio: number, nuevo: boolean = false): FormGroup {
    return this.formBuilder.group({
      idConcepto: idConcepto,
      nombreConcepto: nombreConcepto,
      precio: [precio, [
        Validators.required,
        Validators.pattern(/^(?:[1-9]\d*|0)?(?:\.\d+)?$/),
        Validators.min(1)
      ]],
      precioOriginal: [precio],  // Almacena el precio original para referencia
      nuevo: nuevo
    });
  }
  

  private cargarFinanciera(idFinanciera: number): void {
    this.lenderService.obtenerFinanciera(idFinanciera)
    .pipe(take(1))
    .subscribe((res: any) => {
      this.financieraName = res.data[0].nombre;
    }, (error: any) => {
      this.app.showError(error);
    });
  }

  onSubmit() {
    let conceptos: any[] = [];
    let conceptosBorrados: any[] = [];

    for (let i = 0; i < this.items.length; i++) {
      conceptos.push({
        idConcepto: this.items.controls[i].value.idConcepto,
        precio: this.items.controls[i].value.precio,
        nuevo: this.items.controls[i].value.nuevo
      });
    }

    for (let index = 0; index < this.itemsBorrados.length; index++) {
      const item = this.itemsBorrados[index];
      conceptosBorrados.push({
        idConcepto: item.idConcepto,
        nombreConcepto: item.nombreConcepto,
        precio: item.precio
      });
    }

    let data = { conceptos: conceptos, conceptosBorrados: conceptosBorrados };

    let loading = this.app.showLoading('Guardando información...');
    this.paquetesFinancieraDetalleService.editarConceptos(this.idFinanciera, this.idFinancieraPaquete, data)
      .pipe(take(1))
      .subscribe((res) => {
        this.app.hideLoading(loading);
        this.app.showSnackbar('¡Aviso!', 'Precios actualizados correctamente.', 3000, 'success');
        this.router.navigate([`paquetes-cliente/${this.idFinanciera}`], { queryParamsHandling: 'preserve' });
      }, (err) => {
        this.app.hideLoading(loading);
        this.app.showError(err);
      });
  }

  public onInputLimite(index: number): void{

    if(this.items.controls[index].value.precio > 99999){
        setTimeout(()=>{
          this.items.controls[index].value.precio = 99999;
          this.items.controls[index].get('precio').patchValue(99999);
        }, 10);
    }
  }

  public prohibirCaracteres(event: KeyboardEvent): void {
    const invalidKeys = ['e', 'E', '+', '-'];
  
    // Si la tecla presionada está en la lista de teclas prohibidas, prevenimos su comportamiento
    if (invalidKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
  
  
}
